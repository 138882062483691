import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  // Customizable Area Start
  DialogContent, Dialog, TextField,SwipeableDrawer 
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { loadingImg } from "../../customform/src/assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AppHeader from "../../../components/src/AppHeader.web";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css';
import { View, Image, Text, StyleSheet } from "react-native";
import { imgClose } from "../../categoriessubcategories/src/assets";
import GenericLabel from "../../../components/src/GenericLabel";
import * as Yup from "yup";
import { Formik } from "formik";
import { styled } from "@material-ui/styles";
import "./Settings.css";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';


import Switch from "../../../components/src/Switch.web"
import {
  imgLogo,
  createImage,
  imgUser,
  imgNotification, logOutBtn,group_plus,iconSetting
} from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  
});
const cardData = [
  { heading: "Account", text: "Settings related to your personal information and account credentials.", path: "" },
  { heading: "Team Members", text: "Options for managing team members and their related settings.", path: "TeamBuilderList" },
  { heading: "Profile", text: "Settings and details related to your profile.", path: "CustomisableuserProfiles" },
  { heading: "Terms of Service", text: "In this section, you can review the Terms of Service.", path: "" },
  { heading: "Privacy Policy", text: "Read our Privacy Policy for information on how we handle and protect your data.", path: "" }]
const cardDataClientUser = [{ heading: "Account", text: "Settings related to your personal information and account credentials.", path: "" },
{ heading: "Profile", text: "Settings and details related to your profile.", path: "CustomisableuserProfiles" },
{ heading: "Terms of Service", text: "In this section, you can review the Terms of Service.", path: "" },
{ heading: "Privacy Policy", text: "Read our Privacy Policy for information on how we handle and protect your data.", path: "" }]
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  passWordFn = (touched :any , errors:any) =>{
    return (
      ((touched.confirmPassword && errors.confirmPassword) || (touched.password && errors.password)) && (
        <AlertBox data-testid="alerMessage" style={{marginTop:"-40px",marginBottom:"10px"}}>
          {errors.password ? <div>{errors.password}</div> :  "" }
          {errors.confirmPassword ? <div>{errors.confirmPassword}</div> :  "" }
        </AlertBox>
      ))
    
  }
  saveChangesButton = () =>{
    return(
      <CustomFormWrapper>
      <CustomButtonOutlineSaveChanges />
      <SaveChangesSubmitButton
        type="submit"
        data-test-id="testSaveChangesButton"
        onClick={this.saveChangesBtn}
        disabled={this.state.fieldErrorFirstName || this.state.fieldErrorLastName || this.state.emailErrorMessage === "Email can't be blank."}
        style={{ backgroundColor: this.state.fieldErrorFirstName || this.state.fieldErrorLastName || this.state.emailErrorMessage === "Email can't be blank." ? '#e6e6e6' : '' }}
      >
        Save Changes
      </SaveChangesSubmitButton>
    </CustomFormWrapper>
    )
  }
  cardFn = (item :any , index:any) =>{ return(
    <Box sx={webStyle.leftSmallBox} onClick={() => item?.path ? this.navigateTo(item.path) : this.handleDrawerClick(item.heading)} data-test-id={`testNavigateTo-${index}`}>
    <Box sx={webStyle.box1}>
      <Box style={webStyle.box2}>
        <Box sx={webStyle.box3}>
          <Typography style={{...webStyle.text1,  fontFamily: 'CircularStd',}} key={index}>{item.heading}</Typography>
          <Typography style={{...webStyle.text2, fontFamily: 'CircularStd',}}>{item.text}</Typography>
        </Box>
      </Box>

    </Box>
  </Box>
  )}

  msaUrlDetails(){
    return(
      this.state.msaDocumentsList.msaUrl !== "" && 
      <p data-test-id="msaUrl" style={{fontFamily: "CircularStd",
        lineHeight: "22px",
        overflowWrap:"anywhere",
        fontSize: "14px",
        cursor:"pointer",
        margin:"16px 0",
        textAlign: "left",
        color: "#594FF5",
        }} onClick={()=>this.handlePreview(this.state.msaDocumentsList.msaUrl)} >{this.state.msaDocumentsList.msaLink}
      </p>
    )
  }

  msaSideBarMenu(type:string){
    return (
      this.state.clientTypeCheck === "ClientAdmin" && <Box data-test-id={`drawerMsa-${type}`} sx={webStyle.leftSmallBox} onClick={()=> {this.handleDrawerClick('MSA'); this.setState({selectedMsaFile: this.state.msaDocumentsList.msaFiles[0].url})}}>
        <Box sx={webStyle.box1}>
          <Box style={{ ...webStyle.box2, overflow: 'hidden' }}>
            <Box sx={{ ...webStyle.box3, overflow: 'hidden' }}>
              <Typography style={{ ...webStyle.text1, color: "##000000", fontSize: "16px", alignItems: "center", fontFamily:"CircularStd" }} noWrap >Master Supplier Agreement (MSA)</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isLoading } = this.state;

    if (isLoading) {
      return   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',width:"100%", backgroundColor: 'transparent' }}>
       <img src={loadingImg}  style={{height:"50%",width:"25%"}}/>
  </div>
              }
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container disableGutters maxWidth={false} className="mainDrawerRes">
          <AppHeader
            createImageUrl={createImage}
            helpCenterText="Help Centre"
            logoUrl={imgLogo}
            homeText="Home"
            quoteUrl={group_plus}
            helpCenterNotificationUrl={imgNotification}
            onPressHome={() => this.goToHomeFn()}
            onClickHelpCenter={() => this.goToHelpCenterFn()}
            onClickViewProfile={() => this.goToProfileFn()}
            onClickViewEnquiries={() => this.goToEnquiryFn()}
            data-test-id="testAppHeader"
            onClickSettings={() => this.goToSettings()}
            onClickQuote={() => this.goToQuote()}
            userUrl={imgUser}
            settingsIcon
          />
          {/* Containers */}
          <Box sx={webStyle.columnContainer}>

            {/* CustomBoxLeft */}
            <CustomBoxLeft 
            sx={{...webStyle.leftContainer, fontFamily: 'CircularStd',}}>
              <p style={{...webStyle.settingText, fontFamily: 'CircularStd',}}>Settings</p>
              {this.state.clientTypeCheck === "ClientAdmin" ? (cardData.map((item:any, index:any)=>this.cardFn(item,index))) : (cardDataClientUser.map((item:any, index:any)=>this.cardFn(item,index)))}
              { this.msaSideBarMenu("forWeb")}
            </CustomBoxLeft>

            {/* Drawer */}
            <CustomSwipeableDrawer anchor="left" open={this.state.isDrawerOpen} onClose={this.toggleDrawer} onOpen={this.toggleDrawer} className="drawerRes">
            <Box sx={{ 
              boxSizing: "border-box",padding: "20px 20px 10px 20px", fontFamily: 'CircularStd'}}>
                 <img src={imgClose} onClick={this.toggleDrawer} style={{float:"right",cursor:"pointer"}}/>
              <CustomTextBox2 style={{margin:0}}>
                <img src={iconSetting} onClick={this.toggleDrawer} style={{cursor:"pointer"}}/>
                <div style={{fontWeight: 700, fontSize: "20px",fontFamily: 'CircularStd',cursor:"pointer"}} onClick={this.toggleDrawer}>Settings</div>
              </CustomTextBox2>
              <Box style={{paddingLeft:"20px",paddingRight:"20px"}}>
                {this.state.clientTypeCheck === "ClientAdmin" ? (cardData.map((item:any, index:any)=>this.cardFn(item,index))) : (cardDataClientUser.map((item:any, index:any)=>this.cardFn(item,index)))}
                { this.msaSideBarMenu("forMobile") }
              </Box>
            </Box>
            </CustomSwipeableDrawer>
            {/* Right Container */}
            <CustomBoxRight sx={webStyle.rightContainer}>

            {this.state.rightSideComponent === "Account" && <AccountContainer>
                <CustomTextBox1 component="p" style={{ paddingTop: "10px", 
                fontWeight: 700, fontSize: "30px",fontFamily: 'CircularStd',marginBottom:"55" }}>
                  Account
                </CustomTextBox1>

                <CustomTextBox2>
                  <img src={iconSetting} onClick={this.toggleDrawer} style={{cursor:"pointer"}}/>
                  <div style={{fontWeight: 700, fontSize: "20px",fontFamily: 'CircularStd',cursor:"pointer"}} onClick={this.toggleDrawer}>Settings</div>
                </CustomTextBox2>

                {/* Right SubContainer */}
                <Box style={{ height: "100%", }}>
                    <Box style={{paddingBottom:"20px"}}>
                      <CustomTypography>Account Details</CustomTypography>
                    </Box>
                  <CustomInputGrid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <CustomFormLabel>First Name</CustomFormLabel>
                      <input style={{...webStyle.formInput,fontFamily: 'CircularStd', outline:'none'}} value={this.state.firstName} name="firstName" onChange={this.handleInputChange} data-test-id="testFname" placeholder="First Name"/>
                      {this.state.fieldErrorFirstName && <AlertBox>{this.state.fieldErrorMessageFirstName}</AlertBox>}

                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <CustomFormLabel>Last Name</CustomFormLabel>
                      <input style={{...webStyle.formInput,fontFamily: 'CircularStd',outline:'none'}} value={this.state.lastName} name="lastName" onChange={this.handleInputChange} data-test-id="testLname" placeholder="Last Name"/>
                      {this.state.fieldErrorLastName && <AlertBox>{this.state.fieldErrorMessageLastName}</AlertBox>}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <CustomFormLabel>Location</CustomFormLabel>
                      <input style={{...webStyle.formInput,fontFamily: 'CircularStd',outline:'none'}} value={this.state.location} name="location" onChange={this.handleInputChange} data-test-id="testLocation" placeholder="Location"/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <CustomFormLabel>Company</CustomFormLabel>
                      <input style={{ ...webStyle.formInput, ...{ backgroundColor: "#D9D9D9",fontFamily: 'CircularStd', } }} readOnly value={this.state.companyName} data-test-id="testCompany" placeholder="Company Name"/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <CustomFormLabel>Email</CustomFormLabel>
                      <input style={{...webStyle.formInput,fontFamily: 'CircularStd',outline:'none'}} value={this.state.email}name="email" onChange={this.handleInputChange} data-test-id="testEmail" placeholder="Email Address" autoComplete="off"/>
                      {this.state.fieldErrorEmail && <AlertBox>{this.state.emailErrorMessage}</AlertBox>}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <CustomFormLabel>Mobile Number</CustomFormLabel>
                      <PhoneInput
                        data-test-id="testMobileNumber"
                        country={'us'}
                        inputStyle={{ border: '3px solid #000', width: '100%', height: '36px' ,fontFamily: 'CircularStd',paddingBottom:"8px",paddingTop:"8px", borderRadius:"0px",boxShadow:'none'}}
                        buttonStyle={{
                          borderLeft: '3px solid #000',
                          borderTop: '3px solid #000',
                          borderBottom: '3px solid #000',
                          borderRight:"none",
                          backgroundColor:"#FFFFFF",

                        }}
                        dropdownStyle={{ height: 100 ,textAlign: 'center'}}
                        value={this.state.phone}
                        onChange={(e: any) => this.setState({ phone: e })}
                        placeholder="Mobile Number"
                      />
                    </Grid>
                  </CustomInputGrid>

                  <CustomHrTag />

                  <CustomBox className="changePasswordBlock">
                    <Box style={{ width: "35%" }}>
                      <Typography style={{
                        fontWeight: 700,
                        fontSize: "16px",fontFamily: 'CircularStd',
                      }}>Password</Typography>
                    </Box>
                    <Box style={{ width: "45%" }}>
                      <Box>
                        <Typography style={{
                          fontWeight: 700,
                          fontSize: "14px",fontFamily: 'CircularStd',
                        }}>Password</Typography>
                      </Box>
                      <Box>
                        <Typography style={{
                          fontWeight: 450,
                          fontSize: "14px",fontFamily: 'CircularStd',
                        }}>*********</Typography>
                      </Box>
                    </Box>
                    <Box  style={{marginLeft:"auto", marginRight:"10px"}}>
                      <div
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "#594FF5",
                          cursor: "pointer",fontFamily: 'CircularStd',
                        }}
                        onClick={() => this.passwordModalOpen()}
                        data-test-id="testChangePasswordBtnWeb"
                      >
                        Change Password
                      </div>
                    </Box>
                  </CustomBox>
                  
                  <CustomHrTag/>

                  <CustomBox>
                    <Box style={{ width: "35%",
                    fontSize: "16px", fontWeight: 500,fontFamily: 'CircularstdBold', }}>Notifications</Box>
                    <Box>
                      <Box style={{ display: "flex", flexDirection: "column", 
                      fontSize: "14px", fontWeight: 500, paddingBottom: "8px",fontFamily: 'CircularstdBold', }}>Notification Preferences</Box>
                      <Box style={{ display: "flex", gap: "2rem", 
                      fontSize: "14px", fontWeight: 450, alignItems: "center" ,fontFamily: 'CircularStd'}}>
                        <Box style={{display:"flex", alignItems:"end",height:"27px"}}>Enable email notifications</Box>
                        <Box style={{ cursor: "pointer", }}>
                          <Switch isChecked={this.state.isChecked} toggleSwitch={this.toggleSwitch} data-test-id="testSwitchBtn" />
                        </Box>
                      </Box>
                    </Box>
                  </CustomBox>

                  <CustomHrTag/>


                  <CustomBox>
                    <Box style={{ width: "35%", 
                    fontSize: "16px", fontWeight: 500,fontFamily: 'CircularstdBold', }}>Account Actions</Box>
                    <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <Box style={{ marginRight: "6px" }}><img src={logOutBtn}/></Box>
                      <Box style={{ 
                        fontSize: "14px", fontWeight: 500, cursor: "pointer",fontFamily: 'CircularstdBold', }} onClick={this.logout}>Log out</Box>
                      </Box>
                  </CustomBox>
                
                {/* //////// responsive from Password till logout */}
                  <CustomContainer>
                    <Box>
                      <Typography style={{
                        fontWeight: 700,
                        fontSize: "16px", fontFamily: 'CircularStd',paddingBottom: "10px",
                      }}>Password</Typography>
                    </Box>


                    <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" ,paddingBottom:"30px"}}>
                      <Box style={{ display: "flex", flexDirection: "column" ,width:"50%" }}>
                        <Box style={{ width: "100%" }}>
                          <Typography style={{
                            fontWeight: 500,
                            fontSize: "12px", fontFamily: 'CircularStd',
                          }}>Password</Typography>
                        </Box>
                        <Box style={{ width: "100%" }}>
                          <Box>
                            <Typography style={{
                              fontWeight: 450,
                              fontSize: "12px", fontFamily: 'CircularStd',
                            }}>*********</Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <div
                          style={{
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "#594FF5",
                            cursor: "pointer", fontFamily: 'CircularStd',
                          }}
                          onClick={() => this.passwordModalOpen()}
                          data-test-id="testChangePasswordBtn"
                        >
                          Change Password
                        </div>
                      </Box>
                    </Box>


                    <Box>
                      <Typography style={{
                        fontWeight: 700,
                        fontSize: "16px", fontFamily: 'CircularStd',paddingBottom:"10px"
                      }}>Notifications</Typography>
                    </Box>


                    <Box style={{ display: "flex", flexDirection: "row", alignItems: "center",paddingBottom:"30px" , gap:"35px"}}>
                      <Box style={{ display: "flex", flexDirection: "column" , width:"50%"}}>
                        <Box style={{ width: "110%"}}>
                          <Typography style={{
                            fontWeight: 700,
                            fontSize: "13px", fontFamily: 'CircularStd',
                          }}>Notification Preferences</Typography>
                        </Box>
                        <Box style={{ width: "110%" }}>
                          <Box>
                            <Typography style={{
                              fontWeight: 450,
                              fontSize: "12px", fontFamily: 'CircularStd',
                            }}>Enable email notifications</Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box style={{ cursor: "pointer", }}>
                          <Switch isChecked={this.state.isChecked} toggleSwitch={this.toggleSwitch} data-test-id="testSwitchBtn" />
                        </Box>
                    </Box>

                    <Box style={{display:"flex", flexDirection:"row", alignItems:"center",justifyContent:"center",
                  paddingTop:"15px", 
                  paddingBottom:"15px"}}>
                    <Box style={{ marginRight: "6px" }}><img src={logOutBtn}/></Box>
                    <Box style={{ 
                      fontSize: "14px", fontWeight: 500, cursor: "pointer",fontFamily: 'CircularstdBold', }} onClick={this.logout}>Log out</Box>
                    </Box>

                  </CustomContainer>

                  
                    {/* //////// */}
                  <CustomGridResButton>
                      <CustomDiv>
                        <CustomFormWrapper>
                          <CustomButtonOutlineCancel />
                          <SubmitButtonCancel
                            onClick={this.AccountDetailsAPI}

                          >
                            Cancel
                          </SubmitButtonCancel>
                        </CustomFormWrapper>
                      </CustomDiv>
                        <CustomDiv>
                        
                          {this.saveChangesButton()}
                        </CustomDiv>
                  </CustomGridResButton>

                </Box>
              </AccountContainer>}

              {this.state.rightSideComponent === "MSA" && <MsaContainer>
                <Box component="p" style={{ paddingTop: "10px", 
                fontWeight: 700, fontSize: "30px",fontFamily: 'CircularStd',marginBottom:"40px" }}>
                  Master Supplier Agreement (MSA)
                </Box>
                <Box className="msaContainer">
                  <Box className="documentContainer">
                    <p className="title" style={{marginTop:"20px", marginBottom:"25px"}}>Documents</p>
                    <Box>
                      {this.state.msaDocumentsList.msaFiles.map(document=>(
                        <p data-test-id="msaFileName" key={document.filename} style={{fontFamily: "CircularStd",
                          fontSize: "14px",
                          lineHeight: "22px",
                          margin:"16px 0",
                          textAlign: "left",
                          color: "#594FF5",
                          cursor:"pointer",
                          overflowWrap:"anywhere"
                          }} onClick={()=>this.handlePreview(document.url)}>{document.filename}
                        </p>
                      ))}
                      {
                        this.msaUrlDetails()
                      }
                    </Box>
                  </Box>
                  <Box className="iframeContainer">
                  <CustomIframe
                    width="100%"
                    height="600"
                    frameBorder="0"
                    src={`https://docs.google.com/gview?url=${this.state.selectedMsaFile}&embedded=true`}
                    title="Document Preview"
                   >
                    <p>Your browser does not support PDFs.</p>
                  </CustomIframe>
                  </Box>
                </Box>
              </MsaContainer> }

              {this.state.rightSideComponent === "Privacy Policy" && <PrivacyContainer>
                <Box component="p" style={{ paddingTop: "10px", 
                fontWeight: 700, fontSize: "30px",fontFamily: 'CircularStd',marginBottom:"52px" }}>
                  Privacy Policy
                </Box>
                <Box className="container">
                  <Box className="privacyContainer">
                    <Box className="contentBox">
                      <div className="title">Privacy Policy</div>
                      <div className="content" dangerouslySetInnerHTML={{ __html: this.state.privacyStatement.content }}></div>
                    </Box>
                  </Box>
                </Box>
              </PrivacyContainer> }

              {this.state.rightSideComponent === "Terms of Service" && <PrivacyContainer>
                <Box component="p" style={{ paddingTop: "10px", 
                fontWeight: 700, fontSize: "30px",fontFamily: 'CircularStd',marginBottom:"52px" }}>
                  Terms of Service
                </Box>
                <Box className="container">
                  <Box className="privacyContainer">
                    <Box className="contentBox">
                      <div className="title">Terms of Service</div>
                      <div className="content" dangerouslySetInnerHTML={{ __html: this.state.termOfService.content }}></div>
                    </Box>
                  </Box>
                </Box>
              </PrivacyContainer> }

              {/* change password */}
              <Dialog id="model_add" maxWidth="md"
                open={this.state.showPasswordModal}
                onClose={this.passwordModalClose}
                style={{
                  border: '3px solid black'
                }}
                data-test-id="testDialog"
                scroll="body"
              >
                <CustomDialogPasswordConfimation>
                  <div data-test-id="hideModal2"
                    onClick={this.passwordModal} style={{ position: "absolute", right: 20, top: 10, cursor: "pointer", }}>
                    <CustomPasswordImage
                      source={imgClose}
                      resizeMode="cover" />
                  </div>
                  <Box style={{
                    display: "flex", flexDirection: "column", overflowY: "hidden", overflowX: "hidden"
                  }}>
                    <CustomDialogChangeTypography>Change Password</CustomDialogChangeTypography>
                    <View style={{ width: "100%" }}>
                      <Typography
                        variant="h4"
                        component="h2"
                        style={{
                          fontSize: 30,
                          color: "#000000ff",
                          justifyContent: "center",
                          marginBottom: "30px",
                          display: "flex",
                          fontFamily: 'CircularStdBold',
                          width: "100%",
                        }}
                      >
                        {configJSON.changePassword} 
                      </Typography>
                      <Formik
                        initialValues={{ password: "", confirmPassword: "" }}
                        validationSchema={Yup.object().shape(
                          this.state.passwordSchema
                        )}
                        onSubmit={(values, actions) => {
                          this.goToConfirmationAfterPasswordChange(values);
                          actions.setSubmitting(false);
                          actions.resetForm({
                            values: {
                              password: "",
                              confirmPassword: ""
                            }
                          });
                        }}
                        innerRef={this.formikRef}
                        data-testid="changePasswordForm"
                      >
                        {({
                          setFieldValue,
                          values,
                          handleSubmit,
                          touched,
                          errors
                        }) => (
                          <form onSubmit={handleSubmit} className="mt-5 mb-2">
                            <Grid container spacing={0}>
                              
                            {this.state.errorFailureMessage.length > 0 && (
                              <AlertBox data-testid="alerMessage" style={{marginTop:"-40px",marginBottom:"10px"}}>
                                {this.state.errorFailureMessage}
                              </AlertBox>
                              )}

                            {this.passWordFn(touched, errors)}

                              <Grid item xs={12}>
                                <Text style={styles.customLabel}>
                                  New Password
                                </Text>
                                <CustomFormWrapper>
                                  <CustomInputStyle
                                    // autoFocus
                                    fullWidth
                                    name="newPassword"
                                    placeholder="*********"
                                    type={
                                      this.state.enablePasswordField
                                        ? "password"
                                        : "text"
                                    }
                                    
                                    data-testid={"txtInputPassword"}
                                    value={values.password}
                                    inputProps={{
                                      style: {
                                        height: "23", borderRadius: 0,
                                      }
                                    }}
                                    onChange={event => {
                                      setFieldValue(
                                        "password",
                                        event.target.value
                                      );
                                      this.resetErrorMessage();
                                    }}
                                  />
                                  <div
                                    className="eye-icon"
                                    onClick={() => {
                                      this.setState({
                                        enablePasswordField: !this.state
                                          .enablePasswordField
                                      });
                                    }}
                                    data-testid="passwordShowHideIcon"
                                  >
                                    <Image
                                      source={
                                        this.state.enablePasswordField
                                          ? this.imgPasswordVisible
                                          : this.imgPasswordInVisible
                                      }
                                    />
                                  </div>
                                </CustomFormWrapper>

                              </Grid>
                              <Grid item xs={12} style={{ marginTop: 30 }}>
                                <Text style={styles.customLabel}>
                                  {configJSON.reenterLabelPassword}
                                </Text>
                                <CustomFormWrapper>
                                  <CustomInputStyle
                                    fullWidth
                                    arial-label="password"

                                    //  key={values.confirmPassword}
                                    name="confirmPassword"
                                    placeholder="*********"
                                    type={
                                      this.state.btnConfirmPasswordShowHide
                                        ? "password"
                                        : "text"
                                    }
                                    data-testid={"txtInputConfirmPassword"}
                                    value={values.confirmPassword}
                                    inputProps={{
                                      style: {
                                        height: "23",borderRadius:0,
                                      }
                                    }}
                                    onChange={event => {
                                      setFieldValue(
                                        "confirmPassword",
                                        event.target.value
                                      );
                                      this.resetErrorMessage();
                                    }}
                                  />

                                  <div
                                    className="eye-icon"
                                    onClick={() => {
                                      this.setState({
                                        btnConfirmPasswordShowHide: !this.state
                                          .btnConfirmPasswordShowHide
                                      });
                                    }}
                                    data-testid="confirmPasswordShowHide"
                                  >
                                    <Image
                                      source={
                                        this.state.btnConfirmPasswordShowHide
                                          ? this.imgPasswordVisible
                                          : this.imgPasswordInVisible
                                      }
                                    />
                                  </div>
                                </CustomFormWrapper>

                              </Grid>

                              <Grid item xs={12} style={{ marginTop: 30 }}>
                                <CustomFormWrapper>
                                  <CustomButtonOutline />
                                  <SubmitButton
                                    type="submit"
                                    data-testid="changePasswordButton"
                                  >
                                    Change Password
                                  </SubmitButton>
                                </CustomFormWrapper>
                              </Grid>
                            </Grid>
                          </form>
                        )}
                      </Formik>
                    </View>
                  </Box>
                </CustomDialogPasswordConfimation>
              </Dialog>

              {/* Client user */}
              <Dialog id="model_add" maxWidth="md"
                open={this.state.showWarningUser} onClose={this.hideWarningModalUser}
                style={{
                  border: '3px solid black'
                }}>
                <CustomDialogConfimation>
                  <div data-testid="hideModal"
                    onClick={this.hideWarningModalUser} style={{ position: "absolute", right: 20, top: 10, cursor: "pointer", }}>
                    <CustomDialogueCloseIcon
                      source={imgClose}
                      resizeMode="cover" 
                    />
                  </div>
                  <CustomDailogConfirmationBox >
                    <CustomDialogConfirmationTypography1
                      variant="h5"
                    >?</CustomDialogConfirmationTypography1>

                    <CustomDialogConfirmationTypography2
                      variant="h5">
                      Confirmation Required
                    </CustomDialogConfirmationTypography2>
                    <CustomDialogConfirmationTypography3>The email address you have submitted is not within your company domain.</CustomDialogConfirmationTypography3>
                   
                    <CustomDialogConfirmationTypography3>Your company administrator will be notified of this change if you proceed.</CustomDialogConfirmationTypography3>
                  </CustomDailogConfirmationBox>
                  <CustomDialogConfirmationButtom>
                    <div data-testid="hideModalText" onClick={this.hideWarningModalUser} style={{ marginTop: 10, cursor: "pointer", textAlign: "center" }}>
                      <CustomConfirmationText>Cancel</CustomConfirmationText>
                    </div>
                     <View>
                      <CustomOuterDiv className="buttonWrapper btn-green w-390">
                        <div className="buttonOutline" 
                        style={{ backgroundColor: "#00BFBB" }}
                        ></div>
                        <Button
                          data-test-id="clientClientConfirmBtn"
                          type="submit"
                          className="btn-sm w-195"
                          onClick={() => this.confirmBtn()}
                        >Confirm</Button>
                      </CustomOuterDiv>
                    </View>
                  </CustomDialogConfirmationButtom>

                </CustomDialogConfimation>
              </Dialog>
              {/* client admin */}
              <Dialog id="model_add" maxWidth="md"
                open={this.state.showWarningAdmin} onClose={this.hideWarningModalAmin}
                style={{
                  border: '3px solid black'
                }}>
                <CustomDialogConfimation>
                  <div data-testid="hideModal"
                    onClick={this.hideWarningModalAmin} style={{ position: "absolute", right: 15, top: 15, cursor: "pointer", }}><CustomDialogueCloseIcon source={imgClose} resizeMode="cover" 
                    />
                  </div>
                  <CustomDailogConfirmationBox>
                    <CustomDialogConfirmationTypography1
                      variant="h5">?</CustomDialogConfirmationTypography1>
                        <CustomDialogConfirmationTypography2
                      variant="h5">
                      Confirmation Required
                    </CustomDialogConfirmationTypography2>
                    <CustomDialogConfirmationTypography3>The email address you have submitted is not within your company domain.</CustomDialogConfirmationTypography3>
                    <CustomDialogConfirmationTypography3>Your company administrator will be notified of this change if you proceed.</CustomDialogConfirmationTypography3>
                  </CustomDailogConfirmationBox>
                  <CustomDialogConfirmationButtom>
                    <div data-testid="hideModalText" onClick={this.hideWarningModalAmin} style={{ marginTop: 10, cursor: "pointer", textAlign: "center" }}>
                      <CustomConfirmationText>Cancel</CustomConfirmationText>
                    </div>

                    <View>
                      <CustomOuterDiv className="buttonWrapper btn-green w-390">
                        <CustomSmallDiv className="buttonOutline" style={{ backgroundColor: "#00BFBB" }}></CustomSmallDiv>
                        <Button
                          data-test-id="clientAdminConfirmBtn"
                          type="submit"
                          className="btn-sm w-195"
                          onClick={() => this.confirmBtn()}
                        >Confirm</Button>
                      </CustomOuterDiv>
                    </View>
                  </CustomDialogConfirmationButtom>

                </CustomDialogConfimation>
              </Dialog>
              
              {/* success modal for email change*/}

              <Dialog id="model_add" maxWidth="md"
                open={this.state.openSuccessModal} onClose={this.hideSuccessModal}
                style={{
                  border: '3px solid black'
                }}>
                <CustomDialogSuccess>
                  <div data-testid="hideModal" onClick={this.hideSuccessModal} style={{ position: "absolute", right: 20, top: 10, cursor: "pointer", }}>
                    <CustomDialogueCloseIcon source={imgClose} resizeMode="cover"/>
                  </div>
                  <CustomDialogueBox>
                    <CustomCheckIcon/>
                    <CustomDialogTypography 
                    variant="h5">
                      Successfully Updated
                    </CustomDialogTypography>
                  </CustomDialogueBox>

                </CustomDialogSuccess>
              </Dialog> 

              {/* Failure modal for email change */}

              <Dialog id="model_add" maxWidth="md"
                open={this.state.showFailureModal} onClose={this.hideFailureModal}
                style={{
                  border: '3px solid black'
                }}>
                <CustomDialogError>
                  <div data-testid="hideModal" onClick={this.hideFailureModal} style={{ position: "absolute", right: 20, top: 10, cursor: "pointer", }}>
                    <CustomDialogueCloseIcon source={imgClose} resizeMode="cover" />
                  </div>
                  <CustomDailogErrorBox>
                    <CustomClearIcon />
                    <CustomDialogErrorTypography 
                    variant="h5">
                      OOPS!
                    </CustomDialogErrorTypography>
                    <CustomDialogErrorTypography1>Email is already associated with a different account.</CustomDialogErrorTypography1>
                  </CustomDailogErrorBox>

                </CustomDialogError>
              </Dialog> 

              <Dialog id="model_add" maxWidth="md" data-testid="passwordSuccessModal"
                open={this.state.openDialogName === "passwordUpdated"} onClick={this.closePopup} style={{border:'3px solid black'}} scroll="body">
                <ResponsiveDialogContent className="pt-100 pb-80" style={{ alignItems:'center', width:'480px', minHeight:'448px',maxWidth:'100%', display: 'flex',border:'3px solid black', boxSizing:"border-box", flexDirection: 'column'}}> 
                  <div className="closeWrapper" data-testid="closeSuccessPopup" onClick={this.closePopup}  style={{right:'20px', position:"absolute", cursor:"pointer", top:'20px',}}>
                    <Image source={ imgClose} resizeMode="cover" style={{ height:'20px', width:'20px',}}/>
                  </div>
                  <Box className="contentContainer" style={{display: 'flex', justifyContent: 'center', gap: '25px', flexDirection: 'column', alignItems: 'center'}}>
                    <Box className="contentImage" style={{border: 'solid 3px #000',width:'fit-content',backgroundColor: '#00BFBA', padding: '10px',}}>
                      <CheckIcon style={{ fontSize: '48px',width:'60px', height:'60px', color: 'black'}} />
                    </Box>
                    <Typography className="heading" variant="h5" component="h2"style={{ fontSize: 30, color: "#000", marginBottom: "0", textAlign:'center', lineHeight: '44px', fontFamily:'CircularStdBold', backgroundColor: 'transparent',}}>Successfully Updated</Typography>
                    <div className="text" style={{color:"#5A5A5A", fontFamily:'CircularStd', fontSize: 16, lineHeight: '28px', textAlign: 'center', maxWidth: 380, }}>Password updated successfully</div>
                  </Box>
                </ResponsiveDialogContent>
              </Dialog>

            </CustomBoxRight>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomFormWrapper = styled("div")({
  position: "relative",
  height: 46,
  display: "block"
});

const CustomInputStyle = styled(TextField)({
  borderRadius:0,
  "& .MuiInputBase-fullWidth": {
    margin: 0
  },
  "& .MuiInputBase-input": {
    backgroundColor: "#fff",
    height: "40px",
    position: "relative",
    zIndex: 1,
    borderColor: "#000",
    width: "100%",
    paddingLeft: "5px",
    paddingRight: "5px",
    borderStyle: "solid",
    borderWidth: 3,
  },
  "& inpur:before": {
    content: "none"
  },
  "@media screen and (max-width:420px)":{
    borderRadius:0,
    "& .MuiInputBase-fullWidth": {
      margin: 0
    },
    "& .MuiInputBase-input": {
      backgroundColor: "#fff",
      height: "40px",
      position: "relative",
      zIndex: 1,
      borderColor: "#000",
      width: "100%",
      paddingLeft: "5px",
      paddingRight: "5px",
      borderStyle: "solid",
      borderWidth: 3,
    },
    "& inpur:before": {
      content: "none"
    },
  }
});

const ResponsiveDialogContent = styled(DialogContent)({
  '&.pb-80':{
    paddingBottom:'80px'
  },
  '&.pt-100':{
    paddingTop: '100px',
  },
  '@media only screen and (max-width:500px)':{
    width: '390px !important',
    minHeight:'340px !important',
    gap: '20px !important',
    '&.pt-100':{
      paddingTop: '80px',
    },
    '&.pb-35,&.pb-80':{
      paddingBottom: '40px'
    },
    '& .closeWrapper':{
      right: '15px !important',
      top: '15px !important'
    },
    '& .closeWrapper >div':{
      height: '18px !important',
      width: '18px !important'
    },
    '& .contentContainer':{
      gap: '18px !important'
    },
    '& .contentImage':{
      padding: '7px !important'
    },
    '& .contentImage >svg':{
      height: '35px !important',
      width: '35px !important'
    },
    '& .calendarImage >div':{
      height: '30px !important',
      width: '30px !important'
    },
    '& .calendarImage':{
      padding: '12px !important'
    },
    '& .heading':{
      fontSize:'24px !important',
      lineHeight: '36px !important'
    },
    '& .text':{
      fontSize:'14px !important',
      lineHeight: '23px !important',
      marginTop: '5px !important',
      maxWidth: '330px !important'
    },
    '& .icalendar-select':{
      maxWidth:'200px !important'
    },
    '& .ContinueButton':{
      width: '150px !important',
      marginBottom: '15px !important'
    },
    '& .warningLogo':{
      width: '60px !important',
      height: '60px !important'
    },
    '& .warningLogo >h2':{
      fontSize: '40px !important'
    },
    '& .proceedButton':{
      marginTop: "20px"
    },
    '& .proceedButton >div>div':{
      width: '100px !important'
    }
  }
})

const SubmitButton = styled(Button)({
  backgroundColor: "#fff",
  color: "#000",
  border: "solid 3px #000",
  borderRadius: 0,
  textTransform: "none",
  fontSize: "16px",
  width: "100%",
  padding: "4px",
  fontFamily: 'CircularStdBold',
  "&:hover": {
    backgroundColor: "#BDB9FB",
  },
  "&:focus": {
    backgroundColor: "#DEDCFD",
  },
  "@media screen and (max-width:420px)":{
    backgroundColor: "#fff",
    color: "#000",
    border: "solid 3px #000",
    borderRadius: 0,
    textTransform: "none",
    fontSize: "14px",
    width: "75%",
    padding: "4 10 4 10",
    fontFamily: 'CircularStd',
    fontWeight:700,
    "&:hover": {
      backgroundColor: "#BDB9FB",
    },
    "&:focus": {
      backgroundColor: "#DEDCFD",
    },
  }
});

const CustomButtonOutline = styled("div")({
  border: "solid 3px #000",
  height: 33,
  position: "absolute",
  right: "-5px",
  bottom: '-1px',
  width: "98%",
  backgroundColor: "#594FF5",
  zIndex: 0,
  "@media screen and (max-width:420px)":{
    border: "solid 3px #000",
    height: 33,
    position: "absolute",
    right: "24px",
    bottom: '4px',
    width: "71%",
    backgroundColor: "#594FF5",
    zIndex: 0,
  }
});

const SaveChangesSubmitButton = styled(Button)({
  backgroundColor: "#fff",
  color: "#000",
  border: "solid 3px #000",
  borderRadius: 0,
  textTransform: "none",
  fontSize: "16px",
  width: "100%",
  padding: "4px",
  fontFamily: 'CircularStdBold',
  "&:hover": {
    backgroundColor: "#BDB9FB",
  },
  "&:focus": {
    backgroundColor: "#DEDCFD",
  },
  "@media screen and (min-width:320px) and (max-width:500px)":{
    backgroundColor: "#fff",
    color: "#000",
    border: "solid 3px #000",
    borderRadius: 0,
    textTransform: "none",
    fontSize: "12px",
    width: "100%",
    padding: "4px",
    fontFamily: 'CircularStdBold',
    "&:hover": {
      backgroundColor: "#BDB9FB",
    },
    "&:focus": {
      backgroundColor: "#DEDCFD",
    },
  }
});

const CustomButtonOutlineSaveChanges = styled("div")({
  border: "solid 3px #000",
  height: 33,
  position: "absolute",
  right: "-5px",
  bottom: '-1px',
  width: "98%",
  backgroundColor: "#594FF5",
  zIndex: 0,
  "@media screen and (min-width:320px) and (max-width:500px)":{
  border: "solid 3px #000",
  height: 27,
  position: "absolute",
  right: "-5px",
  bottom: '7px',
  width: "95%",
  backgroundColor: "#594FF5",
  zIndex: 0,
}
});

const CustomButtonOutlineCancel = styled("div")({
  border: "solid 3px #000",
  height: 33,
  position: "absolute",
  right: "-5px",
  bottom: '-1px',
  width: "98%",
  backgroundColor: "#FF5B74",
  zIndex: 0,
  "@media screen and (min-width:320px) and (max-width:500px)":{
    border: "solid 3px #000",
    height: 27,
    position: "absolute",
    right: "-5px",
    bottom: '7px',
    width: "95%",
    backgroundColor: "#FF5B74",
    zIndex: 0,
  }
});

const SubmitButtonCancel = styled(Button)({
  backgroundColor: "#fff",color: "#000",border: "solid 3px #000",borderRadius: 0,
  textTransform: "none",fontSize: "16px",
  width: "100%",
  padding: "4px",
  fontFamily: 'CircularStdBold',
  "&:hover":{backgroundColor:  "#FFBDC7",
  },
  "&:focus":{backgroundColor:  "#FFBDC7",},
  "@media screen and (min-width:320px) and (max-width:500px)":{
    backgroundColor: "#fff",color: "#000",border: "solid 3px #000",
  borderRadius: 0,fontSize: "12px",textTransform: "none",
 padding: "4px", width: "100%",
  fontFamily: 'CircularStdBold',
  "&:hover":{backgroundColor:  "#FFBDC7",},
  "&:focus": {backgroundColor:  "#FFBDC7",},
  }
});

const AlertBox = styled("div")({
  padding: 20,
  backgroundColor: "#FEE2E2",
  borderLeftColor: "#FF5B74",
  boxSizing: 'border-box',
  borderTopLeftRadius: 5,
  borderBottomLeftRadius: 5,
  fontFamily: 'CircularStd',
  borderStyle: "solid",
  color: "#FF5B74",
  width: '100%',
  border: 0,
  borderLeft: 4,
});

const styles = StyleSheet.create({
  customLabel: {
    fontSize: 16,
    color: "#000",
    marginBottom: 5,
    fontFamily: 'CircularStdBold',
    display: "flex"
  },
  staticBottomContent: {
    maxWidth: '596px',
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "center",
    padding: "0 20px",
  }
});
const webStyle = {
  columnContainer: {
    display: "flex",
    flexDirection: "row",
  },
  leftContainer: {
    backgroundColor: "white",
    width: "23%",
    borderRight: "3px solid black",
    boxSizing: "border-box",
    padding: "0px 20px 10px 20px"
  },
  rightContainer: {
    backgroundColor: "#F7F6FF",
    width: "70%",
    height: "100%",
    padding: "0px 30px",
    display: "flex",
    flexDirection: "column",
    alignItems:"center"
  },
  leftSmallBox: {
    backgroundColor: "#DEDCFD",
    border: "3px solid black",
    height: "auto",
    cursor: "pointer",
    width:"100%",
    marginBottom: "40px",
    boxSizing:"border-box"
  },
  box1: {
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    padding:"15px 30px 15px 15px"
  },
  box2: {
    display: "flex",
    gap: "15px"
  },
  box3: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    maxWidth:"246px",
    width:"100%"
  },
  text1: {
    fontWeight: 700,
    fontSize: "16px",
  
  },
  text2: {
    fontWeight: 450,
    fontSize: "13px"
  },
  userIconImg: {
    width: "24px",
    height: "24px"
  },
  settingText: {
    fontWeight: 700,
    fontSize: "30px",
    padding: "0px 30px"
  },
  //////////
  mainWrapper: {
    display: "flex",
    fontFamily: "Circular Std",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  formInput: 
  { width: "100%", border: "3px solid black", height: "36px", padding: "8px", marginBottom: "10px" , fontSize:"14px" , borderRadius: "0px"}
};

const CustomBoxLeft = styled(Box)({
  fontFamily: 'CircularStd',
  backgroundColor: "white",
    width: "30%",
    borderRight: "3px solid black",
    boxSizing: "border-box",
    padding: "0px 20px 10px 20px",
  "@media screen and (min-width:320px) and (max-width:700px)":{
    display :"none", width:0,
  },
})

const CustomBoxRight = styled(Box)({
  backgroundColor: "#F7F6FF",
  width: "70%",
  height: "100%",
  padding: "0px 30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "@media screen and (min-width:320px) and (max-width:700px)": {
    width: "100%",
  },
  "@media screen and (min-width:320px) and (max-width:500px)": {
    padding: "0 20px"
  }
})

const CustomInputGrid =  styled(Grid)({
  "@media screen and (min-width:320px) and (max-width:500px)": {
    padding :"0 30"
  }
})

const CustomTextBox1 = styled(Box)({
  display:"block", 
  "@media screen and (min-width:320px) and (max-width:700px)": {
    display:"none",
  },
})

const CustomTextBox2 = styled(Box)({
  display:"none", 
  "@media screen and (min-width:320px) and (max-width:700px)": {
    display:"flex", alignItems: "center",gap:"8px" , padding:"35 0 25 0", fontWeight: 700,
  },
})

const CustomTypography =  styled(Grid)({
  fontWeight: 500, fontSize: "16px" ,fontFamily: 'CircularstdBold',
  "@media screen and (min-width:320px) and (max-width:700px)":{
    fontWeight: 700, fontSize: "16px" ,fontFamily: 'CircularStd',
  }
})

const CustomFormLabel =  styled(Typography)({
  fontWeight: 700, fontSize: "16px",fontFamily: 'CircularStd',
  "@media screen and (min-width:320px) and (max-width:414px)": {
    fontWeight: 700, fontSize: "14px" ,fontFamily: 'CircularStd',marginBottom: "2px"
  }
})

const CustomContainer = styled(`div`)({
  display:"none",
  "@media screen and (min-width:320px) and (max-width:500px)": {
    display:"block",padding :"40 0 10 0",
  }
})

const CustomBox = styled(Box)({
  display: "flex", padding: "20px 20px 30px 0px",
  "@media screen and (min-width:320px) and (max-width:500px)": {
    display:"none",padding:0,
  },
  '&.changePasswordBlock':{
    paddingRight:0
  }
})

const CustomGridResButton =  styled(`div`)({
  marginTop: 80, marginBottom: 60,
  display:"flex",
  justifyContent:"end",
  gap:"32px",
  "@media screen and (min-width:320px) and (max-width:500px)": {
    marginTop: "30px", marginBottom:30, marginLeft:0,gap:"20px",paddingRight:"40px"
  }
})

const CustomDiv = styled(`div`)({
width:199,
 "@media screen and (min-width:320px) and (max-width:500px)":{
      width: 120,
      height:35,
  },
  '@media screen and (min-width:320px) and (max-width:375px)':{
    width:100
  }
})

const CustomHrTag = styled(`hr`)({
  display:"block",margin: '20px 0', color: "#CBD5E1",
  "@media screen and (min-width:320px) and (max-width:500px)":{
      display: "none",
  }
})

const CustomSwipeableDrawer = styled(SwipeableDrawer)({
  display:"none",
  '@media screen and (min-width:320px) and (max-width:700px)':{
    display:"block", 
  },
  "@media screen and (min-width:320px) and (max-width:414px)":{
    width:"100%",
    "& .MuiDrawer-paper":{
      width:"100% !important",},
    "& .MuiPaper-root":{
      backgroundColor: "#F7F6FF !important",
    } 
  }
})
///////////
const CustomDialogSuccess = styled(DialogContent)({
  display: "block",
  alignItems: 'center',
  width: 400,
  height: 375,
  border: '3px solid black',
  "@media screen and (min-width:420px) and (max-width:500px)": {
    display: "block",
    alignItems: 'center',
    width: 300,
    height: 210,
    border: '3px solid black'
  },
  "@media screen and (max-width:420px)" :{
    display: "block",
    alignItems: 'center',
    width: 210,
    height: 185,
    border: '3px solid black'
  },
  
})

const CustomDialogueCloseIcon =  styled(Image)({
  height: 18, width: 18,
  "@media screen and (min-width:220px) and (max-width:500px)": {
    height: 15, width: 15,
  }
})


const CustomDialogueBox =  styled(Box)({
  border: '4px solid black', padding: '20px', backgroundColor: 'var(--Status-Green-600, #00BFBA)',
  height: 83, width: 83, display: "flex",
  flexDirection: "column", alignItems: "center",
  position: 'absolute', top: '30%', left: "165px",
  "@media screen and (min-width:420px) and (max-width:500px)": {
    border: '4px solid black', padding: '15px', backgroundColor: 'var(--Status-Green-600, #00BFBA)',
    height: 20, width: 18, display: "flex",
    flexDirection: "column", alignItems: "center",
    position: 'absolute', top: '30%', left: "165px"
  },
  "@media screen and (max-width:420px)" :{
    border: '2px solid black', padding: '4px', backgroundColor: 'var(--Status-Green-600, #00BFBA)',
    height: 20, width: 18, display: "flex",
    flexDirection: "column", alignItems: "center",
    position: 'absolute', top: '30%', left: "120px"
  },
})

const CustomCheckIcon = styled(CheckIcon)({
  fontSize: '48px',
  color: 'black', height: '80px', width: '80px',
  "@media screen and (min-width:220px) and (max-width:500px)": {
    fontSize: '48px',
    color: 'black', height: '20px', width: '30px',
  }
})

const CustomDialogTypography = styled(Typography)({
  fontSize: 30, color: "#000",
  justifyContent: "center", marginBottom: "30px",
  display: "flex", alignItems: "center",
  fontFamily: 'CircularStdBold',
  marginTop: 40, width: 345,
  backgroundColor: "#fff",
  "@media screen and (min-width:420px) and (max-width:500px)": {
    fontSize: 24, color: "#000",
  justifyContent: "center", marginBottom: "30px",
  display: "flex", alignItems: "center",
  fontFamily: 'CircularStdBold',
  marginTop: 40, width: 300,
  backgroundColor: "#fff",
  },
  "@media screen and (max-width:420px)" :{
    fontSize: 16, color: "#000",
  justifyContent: "center", marginBottom: "30px",
  display: "flex", alignItems: "center",
  fontFamily: 'CircularStdBold',
  marginTop: 35, width: 165,
  backgroundColor: "#fff",
  },

})

///////////
const CustomDialogError = styled(DialogContent)({
  display: "block",
  alignItems: 'center',
  width: 400,
  height: 375,
  border: '3px solid black',
  "@media screen and (min-width:420px) and (max-width:500px)": {
    display: "block",
    alignItems: 'center',
    width: 300,
    height: 210,
    border: '3px solid black'
  },
  "@media screen and (max-width:420px)" :{
    display: "block",
    alignItems: 'center',
    width: 210,
    height: 185,
    border: '3px solid black'
  },
  
})

const CustomDailogErrorBox = styled(Box)({
  border: '4px solid black',
  width: 83, display: "flex",
  flexDirection: "column",top: '30%', 
  padding: '20px',
  backgroundColor: 'var(--Status-Red-600, #FF5B74)',
  height: 83,
  alignItems: "center",
  position: 'absolute',
  left: "165px",
  "@media screen and (min-width:420px) and (max-width:500px)": {
    top: '30%', 
    padding: '15px',
    backgroundColor: 'var(--Status-Red-600, #FF5B74)',flexDirection: "column", 
     width: 18, display: "flex",
    alignItems: "center", border: '4px solid black',
    position: 'absolute',left: "165px",height: 20,
  },
  "@media screen and (max-width:420px)" :{
    border: '2px solid black', padding: '4px', backgroundColor:'var(--Status-Red-600, #FF5B74)',
    height: 20,
    width: 18,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: 'absolute', top: '30%',
    left: "120px"
  },
})

const CustomClearIcon = styled(ClearIcon)({
  fontSize: '48px',
  color: 'black', height: '80px', width: '80px',
  "@media screen and (min-width:220px) and (max-width:500px)": {
    fontSize: '48px',
    color: 'black', height: '20px', width: '30px',
  }
})

const CustomDialogErrorTypography = styled(Typography)({
  fontSize: 30, color: "#000",
  justifyContent: "center", marginBottom: "30px",
  display: "flex", alignItems: "center",
  fontFamily: 'CircularStdBold',
  marginTop: 40, width: 345, backgroundColor: "#fff",
  "@media screen and (min-width:420px) and (max-width:500px)": {
    fontSize: 24, color: "#000",
  justifyContent: "center", marginBottom: "8px",
  display: "flex", alignItems: "center",
  fontFamily: 'CircularStdBold',
  marginTop: 40, width: 300,
  backgroundColor: "#fff",
  },
  "@media screen and (max-width:420px)" :{
    fontSize: 12, color: "#000",
  justifyContent: "center", marginBottom: "8px",
  display: "flex", alignItems: "center",
  fontFamily: 'CircularStdBold',
  marginTop: 35, width: 165,
  backgroundColor: "#fff",
  },
})

const CustomDialogErrorTypography1 = styled(Typography)({
  color:"#5A5A5A", fontFamily:"CircularStd", textAlign:"center", fontSize:"14",width:"350px",padding:"0 15 0 0",
  "@media screen and (min-width:420px) and (max-width:500px)": {
    color:"#5A5A5A", fontFamily:"CircularStd", textAlign:"center", fontSize:"12",width:"350px",padding:"0 15 0 0",
  },
  "@media screen and (max-width:420px)" :{
    color:"#5A5A5A", fontFamily:"CircularStd", textAlign:"center", fontSize:"9",width:"350px",padding:"0 5 0 0",
  }
})

///////confirmation modal

const CustomDialogConfimation = styled(DialogContent)({
  display: "block",
  alignItems: 'center',
  width: 400,
  height: 420,
  border: '3px solid black',
  paddingBottom: "40",
  "@media screen and (min-width:420px) and (max-width:500px)": {
    display: "block",
    alignItems: 'center',
    width: 300,
    height: 480,
    border: '3px solid black',
    paddingBottom: "40",
    overflowX: "hidden"
  },
  "@media screen and (max-width:420px)" :{
    display: "block",
    alignItems: 'center',
    width: 220,
    height: 400,
    border: '3px solid black',
    paddingBottom: "40",
    overflowX: "hidden"
  },
  
})

const CustomDialogPasswordConfimation = styled(DialogContent)({
  display: "block",
  alignItems: 'center',
  width: 400,
  minHeight: 420,
  border: '3px solid black',
  paddingBottom: "40",
  "@media screen and (min-width:420px) and (max-width:500px)": {
    display: "block",
    alignItems: 'center',
    width: 280,
    minHeight: 350,
    border: '3px solid black',
    paddingBottom: "40",
    overflowX: "hidden"
  },
  "@media screen and (max-width:420px)" :{
    display: "block",
    alignItems: 'center',
    // width: 220,
    // height:330,
    boxSizing: "border-box",
    // width:"282px",
    width:"275px",
    height:"auto",
    border: '3px solid black',
    // paddingBottom: "40",
    overflowX: "hidden",
    padding: "70 25 35 25",
    textAlign:"center",
  },
})

const CustomDailogConfirmationBox = styled(Box)({
  border: '4px solid black', padding: '20px',
                    backgroundColor: 'var(--Status-Amber-400, #FFBA00)',
                    height: 83, width: 83, display: "flex", flexDirection: "column", alignItems: "center", position: 'absolute', top: '10%', left: "165px",
  "@media screen and (min-width:420px) and (max-width:500px)": {
    border: 'none', padding: 0, backgroundColor: "unset",
    height: 'fit-content', width: '100%', display: "flex",
    flexDirection: "column", alignItems: "center",
    position: "unset", marginTop: "35px",
  },
  "@media screen and (max-width:420px)" :{
    border: 'none', padding: 0, backgroundColor: "unset",
    height: 'fit-content', width: '100%', display: "flex",
    flexDirection: "column", alignItems: "center",
    position: "unset", marginTop: "35px",
  },
})

const CustomDialogConfirmationTypography1 = styled(Typography)({
  fontSize: 88, color: "#000",
  justifyContent: "center", backgroundColor: "var(--Status-Amber-400, #FFBA00)",
  display: "flex", alignItems: "center",
  fontFamily: 'CircularStdBold',
  marginTop: -15,
  "@media screen and (min-width:420px) and (max-width:500px)": {
    fontSize: 55, color: "#000",
    justifyContent: "center", backgroundColor: "var(--Status-Amber-400, #FFBA00)",
    display: "flex", alignItems: "center",
    fontFamily: 'CircularStdBold',
    marginTop: -9, width: "75px", height: "75px", border: "3px solid black"
  },
  "@media screen and (max-width:420px)" :{
    fontSize: 55, color: "#000",
    justifyContent: "center", backgroundColor: "var(--Status-Amber-400, #FFBA00)",
    display: "flex", alignItems: "center",
    fontFamily: 'CircularStdBold',
    marginTop: -9, width: "75px", height: "75px", border: "3px solid black"
  }
})

const CustomDialogConfirmationTypography2 = styled(Typography)({
  fontSize: 30, color: "#000",
  justifyContent: "center", marginBottom: "20px",
  display: "flex", alignItems: "center",
  fontFamily: 'CircularStdBold',
  marginTop: 35, width: 345 , background:"none",
  "@media screen and (min-width:420px) and (max-width:500px)": {
    fontSize: 25, color: "#000",
    justifyContent: "center", marginBottom: "42px",
    display: "flex", alignItems: "center",
    fontFamily: 'CircularStdBold',
    marginTop: 42, width: "100%" , background:"none"
  },
  "@media screen and (max-width:420px)" :{
    fontSize: 20, color: "#000",
    justifyContent: "center", marginBottom: "30px",
    display: "flex", alignItems: "center",
    fontFamily: 'CircularStdBold',
    marginTop: 42, width: "100%" , background:"none"
  }
})

const CustomDialogConfirmationTypography3 = styled(Typography)({
  color:"#5A5A5A", fontFamily:"CircularStd", textAlign:"center", fontSize:"14",width:"350px",padding:"0 15 0 0",
  "@media screen and (min-width:420px) and (max-width:500px)": {
    color:"#5A5A5A", fontFamily:"CircularStd", textAlign:"center", fontSize:"16",width:"100%",
    padding: "unset", marginBottom:"20"
  },
  "@media screen and (max-width:420px)" :{
    color:"#5A5A5A", fontFamily:"CircularStd", textAlign:"center", fontSize:"11",width:"100%",
    padding: "unset",marginBottom:"20"
  }
})

const CustomDialogConfirmationButtom = styled(Box)({
  display: 'flex', marginTop: 350, marginLeft: '30%',
  "@media screen and (min-width:420px) and (max-width:500px)": {
    display: 'flex', margin: "unset", marginTop: "50px", justifyContent: 'center'
  },
  "@media screen and (max-width:420px)" :{
    display: 'flex', margin: "unset", marginTop: "35px", justifyContent: 'center'
  }
})

const CustomConfirmationText = styled(`div`)({
  textDecorationStyle: "solid", textDecorationLine: "underline", color: "#000", fontFamily: 'CircularStd', fontSize: 16,
  "@media screen and (min-width:420px) and (max-width:500px)": {
    textDecorationStyle: "solid", textDecorationLine: "underline", color: "#000", fontFamily: 'CircularStd', fontSize: "15px",
  },
  "@media screen and (max-width:420px)" :{
    textDecorationStyle: "solid", textDecorationLine: "underline", color: "#000", fontFamily: 'CircularStd', fontSize: "14px",
  }
})

const CustomOuterDiv= styled(`div`)({
  marginLeft: 20, width: 120,
    "@media screen and (min-width:420px) and (max-width:500px)": {
      marginLeft: 20, width: 120,
  },
  "@media screen and (max-width:420px)" :{
    marginLeft: 20, width: "85px",
  }
})
const CustomSmallDiv = styled(`div`)({
  border: "solid 3px #000",
height: "33px",
position: "absolute",
right: "-5px",
bottom: "0px",
width:" 98%",
backgroundColor: "#594FF5",
zIndex: 0,
"@media screen and (min-width:420px) and (max-width:500px)": {
  border: "solid 3px #000",
  height: "33px",
  position: "absolute",
  right: "-5px",
  bottom: "0px",
  width:" 98%",
  backgroundColor: "#594FF5",
  zIndex: 0,
},
"@media screen and (max-width:420px)" :{
  border: "solid 3px #000",
  height: "33px",
  position: "absolute",
  right: "-5px",
  bottom: "-1px",
  width:" 95%",
  backgroundColor: "#594FF5",
  zIndex: 0,
}
})

//////////////change password

const CustomDialogChangeTypography = styled(Typography)({
  fontFamily: 'CircularStdBold', fontSize: "30px", padding: "45 0 20", textAlign: "center",
  "@media screen and (min-width:420px) and (max-width:500px)": {
    fontFamily: 'CircularStdBold', fontSize: "20px", padding: "0", textAlign: "center",
  },
  "@media screen and (max-width:420px)" :{
    fontFamily: 'CircularStd', fontSize: "24px", padding: "0", textAlign: "center",fontWeight:500,
    paddingTop:"40px"
  }
})

const CustomPasswordImage = styled(Image)({
  height: 18, width: 18, marginRight: "-10px" ,
  "@media screen and (max-width:420px)" :{
    height: 12, width: 12,
  }
})

const AccountContainer = styled(Box)({
  maxWidth:"800px"
});

const MsaContainer = styled(Box)({
  width:"100%",
  marginBottom:"30px",
  '& .msaContainer':{
    display:"flex",
    gap:"30px"
  },
  '& .documentContainer':{
    width:"20%",
  },
  '& .iframeContainer':{
    width:"80%",
    padding:"35px",
    border: "3px solid #000000",
    background: "#CBD5E1",
  },
  '& .title':{
    fontFamily: "CircularStd",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "28px",
    textAlign: "left",
  },
})

const PrivacyContainer = styled(Box)({
  maxWidth:"860px",
  width:"100%",
  marginBottom:"30px",
  '& .container':{
    display: "flex",
    justifyContent: "center",
  },
  '& .privacyContainer':{
    width:"75%",
    padding:"35px",
    border: "3px solid #000000",
    background: "#CBD5E1",
    marginRight:"30px"
  },
  '& .contentBox':{
    backgroundColor: "#FFFFFF",
    border: "3px solid #000000",
    boxShadow: "0px 4px 4px 0px #00000040",
    padding: "46px",
    paddingTop: "66px"
  },
  '& .title':{
    fontFamily: "CircularStd",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.11999999731779099px",
    textAlign: "center",
  },
  '& .content':{
    marginTop:"35px",
    fontFamily: "CircularStd",
    fontSize: "14px",
    fontWeight: 450,
    lineHeight: "17.71px",
  }
})

const CustomIframe = styled(`iframe`)({
  border:"none",
  maxHeight: "100vh",
  minHeight:"750px",
  boxShadow: "0px 4px 4px 0px #00000040",
  '& .toolbar':{
    display:"none !important"
  },
  '& .toolbarContainer':{
    display:"none !important"
  }
})


// Customizable Area End
