import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const baseURL = require("../../../framework/src/config.js").baseURL;
import { getStorageData } from "../../../framework/src/Utilities";
//@ts-ignore
import _ from "lodash";
import React from "react";
import axios from 'axios';
import moment from "moment";
import momentTimezone from "moment-timezone";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  data: any
  // Customizable Area End
}

// Customizable Area Start
  interface InquiryDataAttributes {
    id: number;
    service_id: number;
    base_service_id: number;
    base_service_detail: BaseServiceDetail;
    extra_services_detail: ExtraServicesDetail;
    lf_admin_approval_required: boolean;
    note: string;
    is_previous: boolean;
    event_date: string;
    input_values: InputValuesData[];
    default_coverages: DefaultCoverage[];
    sub_category_name: string;
    is_service: boolean,
    company_id: number
  }

  export interface UploadiedFilesType {
    name: string;
    progress: number;
    status: string;
    file: File;
  };

  interface InquiryData {
    data:{
      attributes: InquiryDataAttributes;
    } 
  }

  interface ResponseJson {
    inquiry: InquiryData;
    message:string;
  }

  interface BaseServiceDetail {
    data: {
      attributes:{
        input_values: {
          data: InputValuesData[]
        }
      }
    }
  }

  interface ExtraServicesDetail {
    data: ExtraServiceData[];
  }

  interface ExtraServiceData {
    attributes: {
        input_values: {
            data: InputValuesData[];
        };
        service_id: number;
        base_service_id: number;
        service_name: string;
        default_coverages: {
            data: DefaultCoverage[];
        };
    };
  }

  interface InputValuesData {
    id: number;
    attributes: {
        id: number;
        user_input: string;
        input_field: {
            section: string;
            name:string;
        };
    };
  }

  interface DefaultCoverage {
    category: string;
    attributes: {
        category: string;
        title: string;
    };
  }

  interface AddonCoverage {
    [serviceName: string]: {
        [category: string]: string[];
    };
  }

  interface GroupBySection {
    [key: string]: InputValuesData[];
  }

  interface GroupByCoverage {
    [key: string]: DefaultCoverage[];
  }

  interface FieldsResponse {
    [key: string]: string;
  }
// Customizable Area End

interface S {
  // Customizable Area Start
  token: string;
  customForm:any;
  groupByCoverage:any;
  base_service_detail:any;
  groupBySection:any;
  initialValueField:any;
  coverageAccordion:boolean;
  groupByRequiredInformation:any;
  requiredInfoAccordion:boolean;
  categoryBaseID: number;
  categoryId:number;
  packageSlectedList:any[];
  packageSelectedItem:any[];
  additionalServicesSubCategories: any[];
  groupByAddons:any;
  addonCoverage:any;
  eventDetails:{title: string;
  description?: string;
  startTime: string;
  endTime: string;
  location?: string;
  durationError: string;
  };
  groupByServices:any;
  groupByAdditionalAddon:any[];
  uploadedFiles: any[],
  note:string;
  calendarType:any;
  errorMessage:string;
  textcounter:number;
  openDialogName: string;
  daysCoverage:any;
  daysCoverageLastValue: string;
  calculateAmout:boolean,
  selecteddays:boolean;
  category: string,
  filterEnquiries: string,
  status:string,
  isVeiwEnquiry:boolean,
  selectedDocFile: File | null,
  categoryList:any,
  currentPage: number;
  recordsPerPage: number;
  categoryRecordLength:number;
  warningMessage:string;
  address:any;
  postcode :string;
  openModal :boolean;
  errorPostCode : boolean;
  locationCheck :boolean;
  openToExpertDialog : boolean;
  meetWithExpertDialog: boolean;
  approveDialogState : boolean;
  event:any;
  rejectState : boolean;
  isLoading:boolean;
  hubSpotName :{name:string,error:string};
  sortDirection: any;
  requiredInputFieldsValue: any,
  enquiryDetails: any,
  continueToReview: boolean,
  isBespokeRequest: boolean,
  showOption:boolean,
  currentEventData: Date,
  previousEventDateLessThenCurrentDate: boolean,
  dupModalOpen: boolean,
  previousEventDateEqualCurrentDate: boolean,
  draftEnquiryResponse: { lf_admin_approval_required : boolean}
  isCalculateCost:boolean,
  maxUploadLimitReached:boolean,
  isEventStartTime:boolean,
  isEventDateChanged:boolean,
  isEventTimePopulated: boolean,
  is_service:boolean,
  initializePackageSelectedItem: boolean;
  errorCell: boolean;
  isInfoMessage: boolean;
  infoMessage: {
    infoText: string,
    buttonText: string,
  };
  isAVSelected: boolean;
  submissionType: string;
  companyLocations: string[];
  company_id: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  navigation: any;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getInquiryId: any;
  getCategoriesApiCallId:any;
  getAddonApiId:any;
  fileInput: React.RefObject<any>;
  saveInquiryApliCallId:any;
  submitInquiryAPICallID:any;
  draftInquiryAPICallID:any
  calculatedAmountAPiCallId:any;
  changeEnquiresAPICallID:any;
  getMessageApiCallId:any;
  createInquiryId: any;
  getUserListApiMethod:any;
  keyPostCode : string = "";
  approveKey :string = "";
  postalCodeKey :string = "";
  rejectKey :string = "";
  removeAddonApiId:any;
  saveProjectDescriptionApiCallId:string = ""
  uploadAttachmentsApiCallId:string[] = [];
  successfulUploadedFile: UploadiedFilesType[] = [];
  uploadAttachmentsApiCallIdRemove:string = "";
  copyFilesArray:UploadiedFilesType[] = [];
  getLocationAPICallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.fileInput = React.createRef();
    // Customizable Area End

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseToken)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      customForm:{},
      groupByCoverage:{},
      base_service_detail:{},
      groupBySection:{},
      status:"",
      eventDetails:{
        title:"",
        location:"",
        startTime: "",
        endTime: "",
        durationError: "",
      } ,
      initialValueField:{},
      coverageAccordion:true,
      groupByRequiredInformation:[],
      requiredInfoAccordion:true,
      categoryId:0,
      categoryBaseID:0,
      isVeiwEnquiry:false,
      isLoading:true,
      addonCoverage:[],
      event:[],
      calendarType:"calendarIcon",
      calculateAmout:true,
      selectedDocFile: null,
      packageSlectedList:[],
      packageSelectedItem:[],
      additionalServicesSubCategories: [],
      daysCoverage:'',
      daysCoverageLastValue: '',
      sortDirection:null,
      groupByAddons:[],
      groupByServices:[],
      groupByAdditionalAddon:[],
      note:"",
      showOption:false,
      errorMessage:"",
      textcounter:0,
      uploadedFiles: [],
      openDialogName: "",
      category: "",
      filterEnquiries:"day",
      selecteddays:false,
      warningMessage:"",
      categoryList:[],
      currentPage: 1,
      recordsPerPage: 12,
      categoryRecordLength:0,
      address : [],
      postcode : "",
      openModal : false,
      errorPostCode :false,
      locationCheck : false,
      openToExpertDialog :false,
      meetWithExpertDialog: false,
      approveDialogState : false,
      rejectState :false,
      hubSpotName : {name:"",error:""},
      requiredInputFieldsValue: {},
      enquiryDetails: { isEnquiry: false, id: null},
      continueToReview: false,
      isBespokeRequest: false,
      currentEventData: new Date(),
      previousEventDateLessThenCurrentDate: false,
      dupModalOpen: false,
      previousEventDateEqualCurrentDate: false,
      draftEnquiryResponse: {lf_admin_approval_required : false},
      isCalculateCost: false,
      maxUploadLimitReached:false,
      isEventStartTime:false,
      isEventDateChanged:false,
      isEventTimePopulated: false,
      is_service: true,
      initializePackageSelectedItem: true,
      errorCell: true,
      isInfoMessage: false,
      infoMessage: {
        infoText: '',
        buttonText: '',
      },
      isAVSelected: false,
      submissionType: '',
      companyLocations: [],
      company_id: 0
      // Customizable Area End
    };

    // Customizable Area Start
    this.setSumitCustomForm = _.debounce(this.setSumitCustomForm.bind(this), 500);
    this.setCustomFormProjectDescription = _.debounce(this.setCustomFormProjectDescription.bind(this), 500);
    this.getAddons = _.debounce(this.getAddons.bind(this), 500);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseToken) === message.id) {
      await this.handleSessionResponseToken(message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      await this.handleRestAPIResponseMessage(message);  
      if(responseJson?.message && apiRequestCallId == this.saveInquiryApliCallId) {
        if(this.state.selecteddays){
          this.handleDaysCoverageWarning()
        }else if(this.state.calculateAmout){
          this.calculatedAmount()
          this.setState({calculateAmout:true})
        }
        this.handleCalculateCostApiCall();
      }
      else if(responseJson?.message && apiRequestCallId == this.submitInquiryAPICallID) {
        this.handleSubbmmitResFn(responseJson)
      }
      else if(apiRequestCallId == this.approveKey){
        this.handleApproveResFn(responseJson);
      }
      else if(apiRequestCallId == this.draftInquiryAPICallID){
        this.handledraftResFn(responseJson);
      }
      else if(apiRequestCallId == this.rejectKey){
        this.handleRejectResFn(responseJson);
      }  else if (apiRequestCallId === this.createInquiryId) {
          const {id, service_id, base_service_detail} = responseJson.inquiry.data.attributes;
          this.redirectToAVPacks(responseJson, base_service_detail, service_id, id);
      }
      else if(this.uploadAttachmentsApiCallId.includes(apiRequestCallId)) {
        this.uploadResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start


  groupByCoverage (responseJson: any) {
    return responseJson.inquiry.data.attributes.default_coverages.reduce((group:GroupByCoverage, product:DefaultCoverage) => {
      const { category } = product;
      group[category] = group[category] ?? [];
      group[category].push(product);
      return group;
    }, {});
  }

  uploadResponse(responseJson : {message : string}){
    this.duplicateAlertModalOpen(responseJson?.message);
    const fileName = responseJson.message.split("'")[1];
    if (fileName) {
      const newUploadedFile = this.copyFilesArray.find((item: UploadiedFilesType) => item.name === fileName);
      if (newUploadedFile) {
        this.successfulUploadedFile.push(newUploadedFile);
        this.setState({ uploadedFiles: this.successfulUploadedFile });
      }
    }
  }

  duplicateAlertModalOpen(data:string){
    if(data.includes("File must be unique, can't upload the same file")){
      this.setState({dupModalOpen : true});
      const uniqueFileNames = new Set();
      let uniqueFiles:{name: string,progress: number,status: string,file:File}[] = [];
      this.state.uploadedFiles.forEach(obj => {
          if (!uniqueFileNames.has(obj.name)) {
              uniqueFileNames.add(obj.name);
              uniqueFiles.push(obj);
          }
      });      
      this.setState({uploadedFiles: uniqueFiles})
    }
  }

  handleDaysCoverageWarning(){
    if(!["AV Packages", "Audio Packages", "Visual Packages"].includes(this.state.customForm.attributes.service_name) && this.state.daysCoverage.user_input !== "More than 3"){
      this.getMessage();
    }
    this.calculatedAmount();
    this.setState({selecteddays:false})
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    let enquiry = await getStorageData("enquiry");
    this.getcategoryList(enquiry ? enquiry : "");
    const url = window.location.pathname
    const search = new URLSearchParams(window.location.search)   
    this.handleURL(url)  
    if(url.includes("Enquiries") && search.get('id')){       // if we have ID in Enquiries' path
      this.viewInquiry(search.get('id'))      
    }
    this.setState({sortDirection:"asc"})
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.customForm != this.state.customForm && this.state.customForm?.attributes) {
      this.setState({uploadedFiles: this.state.customForm.attributes?.files?.map((file:any) => ({
        name: file.name,
        progress: 100,
        status: 'Completed',
        file: file,
      })) || []});
      this.successfulUploadedFile = this.state.customForm.attributes?.files?.map((file:{name: string}) => ({
        name: file.name,
        progress: 100,
        status: 'Completed',
        file: file,
      })) || [];
    }
    if (this.state.packageSelectedItem !== prevState.packageSelectedItem) { 
      const isAVSelected = this.state.packageSelectedItem.includes('322');
      if (isAVSelected !== prevState.isAVSelected) {
        this.setState({ isAVSelected });
      }
    }
  }

  async componentWillUnmount(){
    document.removeEventListener('keydown', this.handleKeyDown);
 }

 handleKeyDown = (event:any) => {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
};

async handleSessionResponseToken(message: Message) {
  let token = message.getData(getName(MessageEnum.SessionResponseData));
  if (token === undefined) {
      token = await getStorageData("authToken");
  }
  this.setState({ token: token }, () => {
      this.getInquiry();
  });
}


handleSubEnquiryResFn=(userRole:any)=>{
  if(userRole == "ClientAdmin"){
    this.setState({openDialogName:"submit successfully ClientAdmin"})
    const isPrevious = this.state.customForm.attributes.is_previous;
    const isBespokeRequest = this.state.isBespokeRequest;
    const lfAdminApproval = this.state.draftEnquiryResponse.lf_admin_approval_required

    if( !isPrevious && isBespokeRequest===false || isPrevious && lfAdminApproval===false){
      this.approveApiFN();
    }
  }else{
    this.setState({openDialogName:"submit successfully ClientUser"})
  }
}

 handleIsCalculateCost = () =>{
  this.setState({isCalculateCost:true}) 
 }

 handleEventTime=(name:string)=>{
  if(name === 'Event Start Time'){
    this.setState({isEventStartTime: true, isEventDateChanged: true});
  }
  if(name === 'Event Start Time' || name === 'Event End Time'){
    return name;
  }
 }

 handleTimeChange = (event: any, setFieldValue: any, inputId: string) => {
  const selectedTime = event;
  const formattedTime = moment(selectedTime).format("HH:mm");
  setFieldValue(inputId, formattedTime);
  this.setState({ calculateAmout: false, requiredInputFieldsValue: { ...this.state.requiredInputFieldsValue, [inputId]: formattedTime } });
  const inputData = { id: inputId, user_input: formattedTime };
  this.saveData(inputData);
  this.handleIsCalculateCost();
  return formattedTime;
};

waitForEventTimePopulation = (inputName: string, inputId: string) => {
  let intervalId = setInterval(() => {
    if (this.state.isEventTimePopulated) {
      if (intervalId) clearInterval(intervalId);
      const startEventInputValue = this.state.initialValueField[inputId];
      const endEventInputValue = this.state.initialValueField[(parseInt(inputId) + 1).toString()];
      
      if (inputName === 'Event Start Time' && startEventInputValue && endEventInputValue) {
        this.setState({
          eventDetails: {
            ...this.state.eventDetails,
            startTime: startEventInputValue,
            endTime: endEventInputValue,
          },
          isEventTimePopulated: false,
        });
      }
    }
  }, 100);
  
  return intervalId;
};


saveEventTime = (event: any, name: string, inputId: string, setFieldValue: any) => {
  const time = this.handleTimeChange(event, setFieldValue, inputId);
  const inputName = this.handleEventTime(name) as string;

  const intervalId = this.waitForEventTimePopulation(inputName, inputId);

  if (inputName === 'Event End Time') {
    if (intervalId) clearInterval(intervalId);
    this.setState({
      eventDetails: { ...this.state.eventDetails, endTime: time },
      isEventTimePopulated: false,
    });
  }

  return () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  };
};

 checkEventTimeValidation= (name:string)=>{  
  return name === 'Event End Time' && !this.state.isEventStartTime && !(this.state.customForm.attributes.sub_category_name.includes("Multi-Day") || this.state.customForm.attributes.is_bespoke);
 }

 handleCalculateCostApiCall = () =>{
  if(this.state.isCalculateCost) {
    this.calculatedAmount();
    this.setState({isCalculateCost: false});
  }
 }

handleApproveResFn=(responseJson :any)=>{
  if(responseJson.message == "Success"){
    this.setState({openDialogName:"submit successfully ClientAdmin"})
  } 
}

handleSubbmmitResFn=(responseJson :any)=>{
  if(responseJson.message ==="Inquiry successfully submitted"){
    const userRole = localStorage.getItem("role")
    this.handleSubEnquiryResFn(userRole);
  } else{
   this.setState({openDialogName:"Error 2",errorMessage:responseJson.message})
  }
}

handledraftResFn= async (responseJson: ResponseJson)=>{
  if(responseJson.message == "Inquiry successfully draft"&&this.state.openDialogName === "Draft"){
    localStorage.removeItem("enqiry");
    localStorage.setItem("enqiry", "draft");
    this.setState({ category: 'Draft' }, () => {
        this.props.navigation.navigate("Enquiries");
    }); 
  } 
  else if(responseJson?.inquiry){
    this.setState({draftEnquiryResponse: { lf_admin_approval_required: responseJson.inquiry.data.attributes.lf_admin_approval_required}});
    const popupName = await getStorageData("role")
    this.setState({ openDialogName: popupName || "" }, ()=>this.getInquiry());
  }
   if(responseJson.message === "Event duration must be less than 9 hours"){
    this.setState({openDialogName:"RequiredFieldValidation", errorMessage:"Event duration can't be more than 9 hours"})
  }  
}


handleRejectResFn=(responseJson :any)=>{
  if(responseJson.message == "Success"){
    this.setState({openDialogName:"",rejectState : true})
  }
}

////////////
async handleRestAPIResponseMessage(message: Message) {
  const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

  if (apiRequestCallId === this.getCategoriesApiCallId) {
      this.handleCategoriesApiResponse(responseJson);
      this.setState({categoryList:responseJson,isLoading:false,currentPage:1})
  }
  else if (apiRequestCallId === this.getAddonApiId) {
    this.getInquiry()
  }
  else if (apiRequestCallId === this.postalCodeKey) {
    this.postalCodeOptions(responseJson)
  }
  else if (apiRequestCallId === this.getMessageApiCallId) {
    this.handleGetMessageResponse(responseJson);
  }
  else if (responseJson?.inquiry !== undefined && responseJson?.inquiry.data) {
    this.handleInquiryResponse(apiRequestCallId, responseJson);
 }
 else if(responseJson.message === "Inquiry not found" && apiRequestCallId === this.getInquiryId && (this.state.enquiryDetails.id || this.props.navigation.getParam("id"))){
  this.setState({ openDialogName: "" }, () => {
      this.props.navigation.navigate("NotAuthorized");
  });
 }
 else if(apiRequestCallId === this.calculatedAmountAPiCallId){
  if(responseJson.errors){
    this.setState({ warningMessage: responseJson.errors[0].error, meetWithExpertDialog: true})//dialog with expert for more that 3 days coverage
   }
 }
  else if(apiRequestCallId === this.getLocationAPICallId){
    this.handleCompanyLocations(responseJson)
  }
 
}

handleCategoriesApiResponse(responseJson: any) {
  
  if (responseJson?.data !== undefined && responseJson?.data) {
    const filteredCategories = responseJson.data.filter((categoryList: any) => {
      return (
        categoryList.attributes.id !== this.state.categoryId &&
        categoryList.attributes.id !== this.state.categoryBaseID
      );
    });
    const packageList = filteredCategories.map((categoryList: any) => {    
      const subCategories = categoryList.attributes.sub_categories?.data?.map(
        (subCategory: any) => subCategory.attributes.name
      ) || [];
      
      return {
        id: categoryList.id,
        title: categoryList.attributes.name,
        subCategories: subCategories, // Add sub_categories names
      };
    });

    this.setState({ packageSlectedList: packageList });
  } else {
    this.setState({ isLoading: false });
  }
}


trimString(givenString: string, maxLength: number) {
  if(givenString){
    if (givenString.length > maxLength) {
      return givenString.slice(0, maxLength) + '...';
    }
    return givenString;
  }
};

handlePopulateEndTime=(responseJson: ResponseJson)=>{
  if(this.state.isEventDateChanged){
    const {base_service_detail, extra_services_detail} = responseJson.inquiry.data.attributes;
    const fields = this.extractFields(base_service_detail, extra_services_detail);
    const fieldsResponse = this.constructResponse(fields, responseJson.inquiry.data.attributes.note)
    const groupBySection = this.handleGroupBySection(base_service_detail);
    this.setState({initialValueField: fieldsResponse, requiredInputFieldsValue: this.constructInitialRequiredInfo(groupBySection["required_information"]), isEventDateChanged:false, isEventTimePopulated: true});
   }
}

handleInquiryResponse(apiRequestCallId:any, responseJson:any) {
  if (apiRequestCallId === this.getInquiryId) {
    this.handleGetInquiryResponse(responseJson);
   } 
   else if (apiRequestCallId == this.changeEnquiresAPICallID) {
     this.props.navigation.navigate("CustomForm",{ id: responseJson.inquiry.data.attributes.id})
     this.setState((prevState) => ({
      openDialogName: "",
      initializePackageSelectedItem: true,
      customForm: {
        ...prevState.customForm,
        attributes: {
          ...prevState.customForm.attributes,
          sub_category_name: responseJson.inquiry.data.attributes.sub_category_name
        }
      }
    }));
    
   }
   else if (apiRequestCallId == this.calculatedAmountAPiCallId) {
     this.setState({ customForm: responseJson.inquiry.data, eventDetails: {...this.state.eventDetails, startTime: responseJson.inquiry.data.attributes.event_time} });
     this.handlePopulateEndTime(responseJson)
 }
 this.setState({isLoading:false})
}

  setDefaultInputValues = (groupBySection: any, input_name: string, value: any) => {
    let requiredInformation = groupBySection["required_information"];
    requiredInformation = requiredInformation.map((input: any) => {
      if (input.attributes.input_field.name === input_name) {
        input.attributes.user_input = value;
      }
      return input;
    });
    return requiredInformation;
  };

  inputFieldsUpdateForFormik = (fields: any, responseJson: any, groupBySection: any, fieldsUpdateFunction: any) => {
    const fieldsResponseUpdated = this.constructResponse(fields, responseJson.inquiry.data.attributes.note)
    this.setState({initialValueField: fieldsResponseUpdated, requiredInputFieldsValue: fieldsUpdateFunction(groupBySection)});
  }

  handleGetInquiryResponse(responseJson: ResponseJson){
    if(this.state.enquiryDetails.isEnquiry){
      this.getCategories();
    }
    const baseServiceId = responseJson.inquiry.data.attributes.base_service_id;
    this.setState({
      customForm: responseJson.inquiry.data,
      categoryId : responseJson.inquiry.data.attributes.service_id,
      categoryBaseID: baseServiceId,
      base_service_detail: responseJson.inquiry.data.attributes.base_service_detail?.data?.attributes || {},
      is_service: responseJson.inquiry.data.attributes.is_service,
      company_id: responseJson.inquiry.data.attributes.company_id,
    }, () => this.getCompanyLocations());

    const  {base_service_detail, extra_services_detail} = responseJson.inquiry.data.attributes

    const fields = this.extractFields(base_service_detail, extra_services_detail);

    const fieldsResponse = this.constructResponse(fields, responseJson.inquiry.data.attributes.note)
    this.setState({initialValueField: fieldsResponse})         
      
    if(responseJson.inquiry.data.attributes.is_previous){
      this.setPreviousEventDate(responseJson.inquiry.data.attributes.event_date)
    }
      
    if(responseJson.inquiry.data.attributes.note){
      this.setCustomFormProjectDescription(responseJson.inquiry.data.attributes.note);
      this.setState({textcounter: responseJson.inquiry.data.attributes.note.length})
    }
      
    const groupBySection = this.handleGroupBySection(base_service_detail);

    const modifiedRequiredInformation = this.setDefaultInputValues(groupBySection, configJSON.stagesOfContent, '1');
    this.setDefaultInputValues(groupBySection, 'Location / Venue',this.state.companyLocations)
    this.inputFieldsUpdateForFormik(fields, responseJson, groupBySection, this.setDefaultInputValues)
    const eventStartDetails = groupBySection["required_information"].find((obj:InputValuesData)=>obj.attributes.input_field.name === "Event Start Time");
    if(eventStartDetails?.attributes.user_input){
      this.setState({ isEventStartTime : true });
    };

    const groupByCoverage = responseJson.inquiry.data.attributes.default_coverages.reduce((group:GroupByCoverage, product:DefaultCoverage) => {
      const { category } = product;
      group[category] = group[category] ?? [];
      group[category].push(product);
      return group;
    }, {});
      
    const addonCoverage = this.groupAddonCoverage(extra_services_detail.data);

    const defaultAdditionaPackage = extra_services_detail.data.map((group:ExtraServiceData) => group.attributes.service_id.toString());

    this.setState({
      groupByCoverage: groupByCoverage,
      addonCoverage:addonCoverage,
      groupByRequiredInformation: modifiedRequiredInformation,
      groupByAddons: groupBySection["addon"],
      groupByServices: groupBySection["services"],
      groupByAdditionalAddon: extra_services_detail.data,
      groupBySection: groupBySection,
      isBespokeRequest: this.state.customForm.attributes.sub_category_name == "Bespoke Request",
      requiredInputFieldsValue: this.constructInitialRequiredInfo(groupBySection["required_information"])
    })
        
    if(this.state.initializePackageSelectedItem)
    {
      this.setState({
        packageSelectedItem : defaultAdditionaPackage,
        initializePackageSelectedItem: false
      })
    }
  }

  extractFields(baseServiceDetail:BaseServiceDetail, extraServicesDetail: ExtraServicesDetail){
    let fields = [];
    if(extraServicesDetail.data.length > 0) {
      const AddonListArray = extraServicesDetail.data.map((inputList: ExtraServiceData) => inputList.attributes.input_values.data).flat()
      fields = [...baseServiceDetail.data.attributes.input_values.data,...AddonListArray]
    } else {
      fields = baseServiceDetail.data.attributes.input_values.data
    }
    return fields;
  }

  constructResponse(fields: InputValuesData[], note: string){
    let fieldsResponse : FieldsResponse= {}
    fields.forEach((item:InputValuesData) => { fieldsResponse[String(item.id)] = item.attributes.user_input} );
    fieldsResponse["note"] = note;
    return fieldsResponse
  }

  handleGroupBySection = (base_service_detail:BaseServiceDetail)=>{
    return base_service_detail.data.attributes.input_values.data.reduce((group:GroupBySection, inputDataSection: InputValuesData) => {
      const { section } = inputDataSection.attributes.input_field;
      group[section] = group[section] ?? [];
      group[section].push(inputDataSection);
      return group;
    }, {});
  }

  handleCompanyLocations = (responseJson: any) => {
    if(!responseJson.error){
      const locations = responseJson.company_locations.map((item: any) => {
        return item.venue_location;   
    });
    const flattenedLocations = [].concat(...locations);
    this.setState({ companyLocations: flattenedLocations });
    } else {
      return
    }
  }
  handleLocationSelection = (e: any) => {
    this.setState({eventDetails: {...this.state.eventDetails, location: e.target.value}})
  }
  setPreviousEventDate(eventDate: string){
    const event_Date = new Date(eventDate)          
    this.setState({currentEventData: event_Date})
  }

  groupAddonCoverage(extraServicesData: ExtraServiceData[]) {
    return extraServicesData.reduce((group: AddonCoverage, service: ExtraServiceData) => {
        const serviceName = service.attributes.service_name;
        const defaultCoveragesData = service.attributes.default_coverages;
        if (defaultCoveragesData) {
            defaultCoveragesData.data.forEach((coverage: DefaultCoverage) => {
                const { category, title } = coverage.attributes;
                group[serviceName] = group[serviceName] || {};
                group[serviceName][category] = group[serviceName][category] || [];
                group[serviceName][category].push(title);
            });
        }
        return group;
    }, {});
  }

  constructInitialRequiredInfo(groupByRequiredInformation: InputValuesData[]) {
    let initialRequiredInfo = {};
    groupByRequiredInformation.forEach((info: InputValuesData) => {
        initialRequiredInfo = { ...initialRequiredInfo, [info.attributes.id]: info.attributes.user_input };
    });
    return initialRequiredInfo;
  }

  filterAdditionalSubCategory() {
    const { packageSelectedItem, packageSlectedList } = this.state;

    const additionalServicesSubCategories = (Array.isArray(packageSelectedItem) ? packageSelectedItem : []).map(itemId => {
      const packageItem = packageSlectedList.find(packageItem => packageItem.id === itemId);
      if (packageItem && packageItem.subCategories.length > 0) {
        return {
          ...packageItem,
          selectedSubCat: packageItem.subCategories[0]
        };
      }   
    });

    this.setState((prevState) => ({
      ...prevState,
      additionalServicesSubCategories: additionalServicesSubCategories
    }));
  } 

  handleSubCatChange = (event: any, additionalPackageId: any) => {
    const { additionalServicesSubCategories } = this.state;
    const updatedSubCategories = additionalServicesSubCategories.map((packageItem) => {
      if (packageItem.id === additionalPackageId) {
        return {
          ...packageItem,
          selectedSubCat: event.target.value // Update selectedSubCat with the new selected value
        };
      }
      return packageItem;
    });
  
    this.setState({
      additionalServicesSubCategories: updatedSubCategories
    });
  }

handleGetMessageResponse(responseJson: any) {
  if(this.state.customForm.attributes.sub_category_name === 'Multi-Day'){
    this.setState({openDialogName: ''})
  }
  if (responseJson.message !== "How many days coverage? option not selected for this inquiry" 
  && responseJson.message !== "No need to change package duration" 
  && responseJson.message !== "Inquiry saved successfully" 
  && this.state.daysCoverage.user_input !== "More than 3"
  && this.state.customForm.attributes.sub_category_name !== 'Multi-Day'){
   this.setState({ warningMessage: responseJson.message, openDialogName: "Warning" }) //logic for 'more than 3 popup is on handleRestAPIResponseMessage method
  }

}


  
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionResponseToken)
    );
    this.send(msg);
  };
  goToHome = () => {
    this.props.navigation.navigate("LandingPage");
  }
  handleModalClose =()=>{
    if(this.state.customForm.attributes.status==="draft"){
      this.setState({openDialogName:""})  
    }else{
      this.goToEnquiryCustom()
    }
  }
  goToEnquiryCustom = () => {
    localStorage.removeItem("enqiry");
    localStorage.setItem("enqiry", "");
    this.props.navigation.navigate("Enquiries");
  }
  changeEnquiriesClick =() =>{
    this.changeEnquiries();
  }

  handlePackageSelection = (event:any) => {
    let {value} = event.target;
    const {service_name} = this.state.base_service_detail;
    if((value.includes('322') || service_name === 'Audio Packages' || service_name === 'Visual Packages')
       && value.includes('300') || value.includes('301')) {
      value.filter((item: string) => item !== '300' && item !== '301')

    } else {
      this.setState({packageSelectedItem: value}, () => {
        this.getAddons();
        this.filterAdditionalSubCategory()
      });
    }
  }

  getCategories = () => {
    if (!this.state.token) {
      return;
    }
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAddons = () => {
    if (!this.state.token) {
      return;
    }
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAddonApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.manageAddistionalService
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    const httpBody = {
      inquiry_id:this.props.navigation.getParam("id"),
      service_ids:this.state.packageSelectedItem,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  removeAddons = () => {
    if (!this.state.token) {
      return;
    }
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token
    };

    const httpBody = {
      inquiry_id:this.props.navigation.getParam("id"),
      service_ids:this.state.packageSelectedItem,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeAddonApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.manageAddistionalService
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeEnquiries = () =>{
    
    const header = {
      "Content-Type": configJSON.ApiContentType,
       token: this.state.token,
     };
     const requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
     );
   
     this.changeEnquiresAPICallID = requestMessage.messageId;
   
     requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/change_inquiry_sub_category?inquiry_id=${this.props.navigation.getParam("id")}`
    );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.addUserApiMethod
     );
     runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getMessage = () =>{
    const header = {
      "Content-Type": configJSON.ApiContentType,
       token: this.state.token,
     };
     const requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
     );
   
     this.getMessageApiCallId = requestMessage.messageId;
   
     requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/change_inquiry_sub_category?inquiry_id=${this.props.navigation.getParam("id")}&only_message=true`
    );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.addUserApiMethod
     );
     runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInquiry = async() => {

    this.handleCalculateCostApiCall();//this.state.isCalculateCost: true - to make this run. Made to prevent unexpected calls 

    if (!this.state.token) {
      return;
    }

    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInquiryId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getInquiryEndpoint + `/${this.state.enquiryDetails.isEnquiry ? this.state.enquiryDetails.id : this.props.navigation.getParam("id")}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getInquiryFilter = async(filter:any) => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/inquiries?filter_by=${filter}&status=${this.state.status}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  viewInquiry =(id:any) => {
    window.history.pushState(null, "", `/Enquiries?id=${id}`);
    this.setState({ openDialogName: "ViewEnquiryStatus", enquiryDetails: {isEnquiry: true, id:id}, isLoading:true }, ()=>{
      this.getInquiry()
    });
    this.handleDownload(id);
  };

  viewReq=(reqID : string) => {
    this.setState({enquiryDetails: {isEnquiry: true, id:reqID}})
    this.handleDownload(reqID);
  }

  saveData = (userVal:any)=> {
    this.setSumitCustomForm([userVal].flat())
  }

  onCancel = () => {
    
    if(this.state.openDialogName == "upload"){
      this.setState({ openDialogName: "" },()=>this.getInquiry());
    }
    else if(this.state.openDialogName == "ViewEnquiryStatus"){
      this.props.navigation.navigate("Enquiries")
      this.setState({openDialogName: ""})
      if(!this.state.categoryList?.inquiries?.data){
        this.setState({isLoading: true})
      }
    }
    else{
      this.setState({
      openDialogName: "",calendarType:"calendarIcon"
    });
    }
  }

  closeEnquiryPopup=()=>{
    this.props.navigation.navigate("Enquiries")
    this.setState({ openDialogName: "" })
  }

  closeCustomFormPopup=()=>{
    this.setState({ openDialogName: "" });
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getFileName = (fileName:any) => fileName.length >25 ? `${fileName.slice(0, 30)}...` : fileName;

  getInputName = (inputName:string) => inputName.length > 25 ? `${inputName.slice(0, 28)}...` : inputName;

  downloadFile = async (fileName: string, urlLink: string) => {

    const response = await fetch(urlLink);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    window.URL.revokeObjectURL(url);
   
    }

  

  onCancelCalendar = () => {
   const popupName = localStorage.getItem("role")
    this.setState({ openDialogName: popupName || "",calendarType:"calendarIcon" }, ()=>this.getInquiry());
  }

  onEnquiryCancel=()=>{
    this.setState({ openDialogName: "", rejectState: false, isLoading:true},()=>{
      this.getcategoryList(this.state.category);
    });
  }

  onPopCancel = () => {
    const { daysCoverageLastValue } = this.state;
    const fullDayAllowedCoverage = ['1', '2', '3'];
    
    let conditionalDaysCoverage = '1'; // Default value
  
    if (this.state.warningMessage !== 'No need to change package duration') {
      if (this.state.customForm.attributes.sub_category_name === 'Half-Day') {
        conditionalDaysCoverage = '0.5';
      } else if (this.state.customForm.attributes.sub_category_name === 'Full-Day' && fullDayAllowedCoverage.includes(daysCoverageLastValue)) {
        conditionalDaysCoverage = daysCoverageLastValue;
      }
    }
  
    this.setState(
      {
        daysCoverage: { id: this.state.daysCoverage.id, user_input: conditionalDaysCoverage },
        openDialogName: "",
      },
      () => {
        this.saveData(this.state.daysCoverage);
        this.getInquiry();
      }
    );
  };
  
  onAddBtnClicked = () => {
    this.setState({ openDialogName: "upload" });
  }

  handleEventDurationValidation () {
    let errorMessage = ''
    const startTime = parseInt(this.state.eventDetails.startTime);
    const endTime = parseInt(this.state.eventDetails.endTime);
    const {sub_category_name} = this.state.customForm.attributes;
    const diff = endTime - startTime;
    if(diff > 5 && sub_category_name.includes('Half-Day')){
      return errorMessage = 'more than 5'
    } else if(diff > 9 && sub_category_name.includes('Full-Day')) {
      return errorMessage = 'more than 9'
    }

  }

  onReviewBtnClicked = () => {
    this.setState({ continueToReview: true });
    let isPreviousEventDateLessThenCurrentDate = false;
    let isPreviousEventDateEqualCurrentDate = false;
    if(this.state.customForm.attributes.is_previous){
      const previousEventDate = moment(this.state.customForm.attributes.parent_inquiry_event_date)
      const currentEventDate = moment(this.state.currentEventData);
      isPreviousEventDateLessThenCurrentDate = currentEventDate.isBefore(moment().startOf('day'));      
      isPreviousEventDateEqualCurrentDate = currentEventDate.isSame(previousEventDate);
      this.setState({previousEventDateEqualCurrentDate: isPreviousEventDateEqualCurrentDate, previousEventDateLessThenCurrentDate: isPreviousEventDateLessThenCurrentDate})
    }
    if(this.checkRequiredFields() || this.state.locationCheck || isPreviousEventDateEqualCurrentDate || isPreviousEventDateLessThenCurrentDate){
      this.setState({openDialogName:"RequiredFieldValidation"});
      return ;
    } 
    
    const durationErrorMessage = this.handleEventDurationValidation();
    if(durationErrorMessage){
     this.setState({eventDetails: {...this.state.eventDetails, durationError: durationErrorMessage}})
    this.setState({openDialogName:"RequiredFieldValidation"});
  } else {
    this.savetoDraft();
  }
    
   
  }
  onSubmitButtonClick = () => {
    this.submitForm();
    this.setState({ openDialogName: "" });
  }
  onApproveButtonClick = () => {
    this.approveForm();
    this.setState({ openDialogName: "" });
  }

  handleReset =(resetData:any, setFieldValue:any) => {
    let resetingValue:any = [];
    let requireFields = {...this.state.requiredInputFieldsValue};

    const input_values = this.state.customForm.attributes.base_service_detail?.data.attributes.input_values
    const clientName = input_values?.data.findIndex((input:{attributes:{input_field:{name:string}}}) => input.attributes.input_field.name === 'Client Name')
    const companyName = input_values?.data.findIndex((input:{attributes:{input_field:{name:string}}}) => input.attributes.input_field.name === 'Company Name')
    
    const ids = this.state.customForm.attributes.files.map((file:any)=> file.id)
    Object.entries(resetData).forEach(([key,value]) =>{
      if(this.state.customForm.attributes.is_previous && (input_values?.data[clientName].attributes.id.toString() === key || input_values?.data[companyName].attributes.id.toString() === key)){
        return 
      }
      resetingValue.push({id: key, user_input:null});
      setFieldValue(key,"");
      if(requireFields[key]){
        requireFields[key] = "";
      }
    });        
    this.setTextcounter(0);
    setFieldValue("subject","");
    this.setState({uploadedFiles: [], packageSelectedItem: [], requiredInputFieldsValue: requireFields, errorPostCode: false, groupByAdditionalAddon: [], isEventStartTime:false}, () => {
      this.removeAddons();
    });    
    this.removeAttachmentsForm(ids);
    this.setSumitCustomForm(resetingValue);

  }

  setSumitCustomForm = (dataVal:any) =>{
     let body ={
      inquiry_id:this.props.navigation.getParam("id"),
      input_values:dataVal
    }

      const header = {
        "Content-Type": configJSON.getUserListApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.saveInquiryApliCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.saveformEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  receiveAVPacks = async() => {

    if (!this.state.token) {
      return;
    }

    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token
    };

    const httpBody = {
      inquiry:{ service_id: 299, sub_category_id:483}
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createInquiryId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCustomFormEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  redirectToAVPacks = (responseJson: any, base_service_detail: any, service_id: number, id: number) => {
    const groupByCoverage = this.groupByCoverage(responseJson)

    this.props.navigation.navigate("CustomForm",{ id: id })

      this.setState({
        isLoading: true, 
        base_service_detail: base_service_detail,
        groupByCoverage: groupByCoverage,
        categoryId: service_id,
        isInfoMessage: false,
        infoMessage: {
          infoText: '',
          buttonText: ''
        }
      })
      this.handleCategoriesApiResponse(this.state.categoryList)
      this.calculatedAmount()
  }

  setCustomFormProjectDescription = (value:string) =>{
    let body ={
      inquiry_id:this.state.customForm.attributes.id,
      note: value
    }    

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.saveProjectDescriptionApiCallId = requestMessage.messageId;
  
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.updateProjectDescription);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPutMethod);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify({"Content-Type": configJSON.getUserListApiContentType,token: this.state.token}));
    runEngine.sendMessage(requestMessage.id, requestMessage);
 }

 isPackageDisabled = (packageName: any) => {
  const { packageSelectedItem, base_service_detail } = this.state;
  if (packageSelectedItem.includes('322') && 
      (packageName.title === 'Visual Packages' || packageName.title === 'Audio Packages')) {
    return true;
  }
  if (base_service_detail.service_name === 'Audio Packages' && packageName.title === 'Visual Packages') {
    return true;
  }
  if (base_service_detail.service_name === 'Visual Packages' && packageName.title === 'Audio Packages') {
    return true;
  }
  return false;
};

  setAttachmentsForm = (file:any) =>{
    let formData = new FormData();
    formData.append('inquiry_id', this.props.navigation.getParam("id"));
    formData.append('file', file.file);

     const header = {
       token: this.state.token,
     };
     const requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
     );
 
     this.uploadAttachmentsApiCallId = [...this.uploadAttachmentsApiCallId,requestMessage.messageId];
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       configJSON.uploadAttacmentEndPoint
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestBodyMessage),
       formData
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.httpPutMethod
     );
     runEngine.sendMessage(requestMessage.id, requestMessage);

 }

 removeAttachmentsForm = (ids:number[]) =>{
  let formData = new FormData();
  formData.append('inquiry_id', this.props.navigation.getParam("id"));  
  ids.forEach((id:any) => {
    formData.append('remove_file_id', id);
  });

   const header = {token: this.state.token,};
   const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
   this.uploadAttachmentsApiCallIdRemove = requestMessage.messageId;

   requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.uploadAttacmentEndPoint
   );
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
   );
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestBodyMessage),formData
   );
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpPutMethod
   );
   runEngine.sendMessage(requestMessage.id, 
    requestMessage);

}

 submitForm = () =>{
   const header = {
    "Content-Type": configJSON.getUserListApiContentType,
     token: this.state.token,
   };
   const requestMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );

   this.submitInquiryAPICallID = requestMessage.messageId;

   requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `/submit_inquiry?inquiry_id=${this.state.enquiryDetails.isEnquiry ? this.state.enquiryDetails.id : this.props.navigation.getParam("id")}&new_status=pending`
  );
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header)
   );
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.httpPutMethod
   );
   runEngine.sendMessage(requestMessage.id, requestMessage);

}

approveForm = () =>{
  const header = {
   "Content-Type": configJSON.getUserListApiContentType,
    token: this.state.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.submitInquiryAPICallID = requestMessage.messageId;

  requestMessage.addData(
   getName(MessageEnum.RestAPIResponceEndPointMessage),
   `/submit_inquiry?inquiry_id=${this.props.navigation.getParam("id")}&new_status=pending`
 );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPutMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}
rejectForm = () =>{
  const header = {
   "Content-Type": configJSON.getUserListApiContentType,
    token: this.state.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.submitInquiryAPICallID = requestMessage.messageId;

  requestMessage.addData(
   getName(MessageEnum.RestAPIResponceEndPointMessage),
   `/submit_inquiry?inquiry_id=${this.props.navigation.getParam("id")}&new_status=rejected`
 );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPutMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}

savetoDraft = () =>{
  const header = {
   "Content-Type": configJSON.getUserListApiContentType,
    token: this.state.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.draftInquiryAPICallID = requestMessage.messageId;

  requestMessage.addData(
   getName(MessageEnum.RestAPIResponceEndPointMessage),
   `/submit_inquiry?inquiry_id=${this.props.navigation.getParam("id")}&new_status=draft`
 );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPutMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}

calculatedAmount = () =>{
  const header = {
   "Content-Type": configJSON.getUserListApiContentType,
    token: this.state.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.calculatedAmountAPiCallId = requestMessage.messageId;

  requestMessage.addData(
   getName(MessageEnum.RestAPIResponceEndPointMessage),
   `/calculate_cost?inquiry_id=${this.state.enquiryDetails.isEnquiry ? this.state.enquiryDetails.id : this.props.navigation.getParam("id")}`
 );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPutMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}

  setTextcounter = (count:any) => {
    this.setState(
      {
        textcounter:count
      }
      )
  }
  
  handlePageChange = (page: number) => {
    this.setState({ currentPage: page });
  };
  
  getcategoryList = async (params:any) => {
    const categoryValue = params !== "" ? params : "All Enquiries";
    const modifiedParams = params !== "All Enquiries" ? params.toLowerCase().replace("enquiries", "").trim() : params;
    this.setState({ category: categoryValue });
    this.setState({status:params})
    let token:any = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: token
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   
      this.getCategoriesApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/inquiries?status=${modifiedParams}`
        );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDocFileChange = (e:any) => {
    const files = e.target.files;
    let newFiles = Array.from(files).map((file) => {
      const typedFile = file as File;
      return {
        name: typedFile.name,
        progress: 0,
        status: 'Uploading',
        file: typedFile
      }
    });
    this.copyFilesArray = [...this.copyFilesArray,...newFiles];
    this.handleLimitedUploadFiles(newFiles);
  };

  closeLimitReachedPopup=()=>{
    this.setState({maxUploadLimitReached: false});
  }

  handleLimitedUploadFiles = (newFiles:{name:string,progress:number,status:string,file:File}[]) =>{
    if(this.state.uploadedFiles.length+newFiles.length > 10){
      newFiles = newFiles.splice(0, 10 - this.state.uploadedFiles.length)
      this.setState({maxUploadLimitReached: true});
    }
    if( newFiles.length> 0 ){
      this.setState((prevState) => ({
        uploadedFiles: [...prevState.uploadedFiles, ...newFiles],
      }));
      newFiles.forEach((newFile) => {
        this.simulateFileUpload(newFile);
      });
    }
  }

  handleSortClick = () => {
    const { sortDirection, categoryList } = this.state;
    const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    categoryList.inquiries.data.sort((a:any, b:any) => {
      const dateA = new Date(a.attributes.event_date).getTime();
      const dateB = new Date(b.attributes.event_date).getTime();

      return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });

    this.setState({ sortDirection: newSortDirection, categoryList: categoryList });
  };

  getTimeString = (value: any) => {
    if (!value) return '';
    const ukAndGreatBritainTimeZones = ['Europe/London', 'Europe/Guernsey', 'Europe/Isle_of_Man', 'Europe/Jersey'];
    const userTimeZone = momentTimezone.tz.guess();
    const isUKOrGreatBritain = ukAndGreatBritainTimeZones.includes(userTimeZone);

    const isDaylightSavingTime = momentTimezone().tz(userTimeZone).isDST();
    if (moment.isMoment(value)) {
      let format = isDaylightSavingTime ? "HH:mm [BST]" : "HH:mm [GMT]";
      if(!isUKOrGreatBritain){
        format = "HH:mm [GMT]"
      }
      return `${value.format(format)}`;
    } else if (typeof value === 'string') {
      let format = `${value} ${isDaylightSavingTime ? 'BST' : 'GMT'}`;
      if(!isUKOrGreatBritain){
      format = `${value} GMT`;
      }
      return format;
    } else if (value instanceof Date) {
      const hours = value.getHours().toString().padStart(2, '0');
      const minutes = value.getMinutes().toString().padStart(2, '0');
      let format = `${hours}:${minutes} ${isDaylightSavingTime ? 'BST' : 'GMT'}`
      if(!isUKOrGreatBritain){
       format = `${hours}:${minutes} GMT`;
      }
      return format;
    } else {
      return '';
    }
};


  simulateFileUpload = (file:any) => {
    const updateProgress = () => {
      if (file.progress < 100) {
        file.progress += 10;
  
        this.setState((prevState) => ({
          uploadedFiles: prevState.uploadedFiles.map((uploadedFile) =>
            uploadedFile === file ? file : uploadedFile
          ),
        }));
        setTimeout(updateProgress, 1000);
      } else {
        file.progress = 100;
        file.status = 'Completed';
        this.setAttachmentsForm(file);
        this.setState((prevState) => ({
          uploadedFiles: prevState.uploadedFiles.map((uploadedFile) =>
            uploadedFile === file ? file : uploadedFile
          ),
        }));
      }
    };
    updateProgress();
  };
  


  handleDragOver = (event: any) => {
    event.preventDefault();
    event.stopPropagation()
  }

  handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer.files;
    let newFiles = Array.from(files).map((file) => {
      const typedFile = file as File;
      return {
        name: typedFile.name,
        progress: 0,
        status: 'Uploading',
        file: typedFile
      }
      });

    this.handleLimitedUploadFiles(newFiles)
  };
  
  setInfoPaperStyle = (isAVSelected: boolean) => ({
    minWidth: isAVSelected ? "342px" : "463px",
  });

  setButtonStyle = (isAVSelected: boolean) => ({
    display: isAVSelected ? 'none' : 'block',
});

  setBackgroundColor  = (selectedSubCat: string, subCategory: string) => {

    return selectedSubCat === subCategory ? "#BDB9FB" : "#fff";
  };

  openDocFileInput = () => {
    if (this.fileInput.current) {
      this.fileInput.current.click();
    }
  };
  setCategoryTxt = (text: string) => {
    this.setState({ category: text }, ()=>this.getcategoryList(this.state.category));
  };

  setFilterTxt = (textLabel: string) =>{
    this.setState({filterEnquiries: textLabel})
    this.getInquiryFilter(textLabel)
  }
  goToHelpCenterCustom() {
    this.props.navigation.navigate("HelpCenter");
  }

  goToService=()=> {
    this.props.navigation.navigate("Categoriessubcategories");
  }

  goToDraftEnquire = async() => {

    const durationErrorMessage = this.handleEventDurationValidation();
    if(durationErrorMessage){
     this.setState({eventDetails: {...this.state.eventDetails, durationError: durationErrorMessage}})
    this.setState({openDialogName:"RequiredFieldValidation"});
    } else {
      this.savetoDraft();
    }
    
};

  goToProfileCustom() {
    this.props.navigation.navigate("CustomisableuserProfiles");
  }
  goToiCalendar=()=> {
    this.getInquiry()
    this.calculatedAmount()
    this.setState({openDialogName:"OpenICalendar"});
    
  }
  goToSettingsCustom(){
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Settings2");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    }
    goToQuoteCustom = () =>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Categoriessubcategories");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    }

  getCompanyLocations = async() => {
    const {company_id} = this.state;
    
    const header = {
      "Content-Type":  configJSON.getUserListApiContentType,
       token: this.state.token,
     };
     const requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
     );
   
     this.getLocationAPICallId = requestMessage.messageId;
   
     requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `/company_locations/${company_id}`
    );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.getApiMethod
     );
     runEngine.sendMessage(requestMessage.id, requestMessage);
  }  

  locationFn = (event :any)=>{
    const inputValue = event.target.value.replace(/\s/g, '');
    if(event.target.value.length >= 1 && event.target.value.length < 9){
       const requestMessage = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
       );
       this.postalCodeKey = requestMessage.messageId;
       requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `https://api.postcodes.io/postcodes?q=${inputValue}`
      );
       requestMessage.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.getApiMethod
       );
       runEngine.sendMessage(requestMessage.id, requestMessage);
      this.setState({openModal: true, errorPostCode:false,locationCheck:true});
    }
    else if(event.target.value.length == 0){
      this.setState({openModal: false,locationCheck:true});
    }
  }

  postalCodeOptions = (response: {result : []}) => {
    let postalData: string[] = [];
    if(response.result){
      response.result.map((code: {postcode : string}) => {
      postalData.push(code.postcode)
    });
    this.setState({ address: postalData });
    }
  };


  toggleDropdown = ()=>{
    this.setState({openModal:!this.state.openModal});
   }
  handleCalendarTypeChange = (event:any) => {
    if(event.target.value ==="download" ||  event.target.value ==="apple"){
       this.setState({calendarType:event.target.value},()=>this.handleCalendarChange());
    }
    else{
      this.setState({calendarType:event.target.value});
    }
  };

  generateUID() {
    let buffer = new Uint32Array(2);
    crypto.getRandomValues(buffer);
    let n = (buffer[0] * 0x100000000 + buffer[1]).toString();
    return new Date().getTime().toString() + "_" + n;
  }

  handleCalendarChange = () => {
    const { calendarType, base_service_detail } = this.state;
  
    const inputValues = base_service_detail.input_values.data;
  
    const eventNameInput = inputValues.find(
      (input:any) => input.attributes.input_field.name === "Event Name"
    );
    const eventName = eventNameInput ? eventNameInput.attributes.user_input : '';
  
    const eventStartTimeInput = inputValues.find(
      (input:any) => input.attributes.input_field.name === "Event Start Time"
    );
    const eventStart = eventStartTimeInput ? eventStartTimeInput.attributes.user_input : "";
  
    const eventEndInput = inputValues.find(
      (input:any) => input.attributes.input_field.name === "Event End Time"
    );
    const eventEnd = eventEndInput ? eventEndInput.attributes.user_input : "";
  
    const eventDateInput = inputValues.find(
      (input:any) => input.attributes.input_field.name === "Event Date"
    );
    const eventDate = eventDateInput ? eventDateInput.attributes.user_input : "";
    
    const eventLocationInput = inputValues.find(
      (input:any) => input.attributes.input_field.name === "Location / Venue"
    );
    const eventLocation = eventLocationInput ? eventLocationInput.attributes.user_input : "";
    try {
      const formattedDate = eventDate.split('T')[0].replace(/-/g, '');
      const formattedStartTime = eventStart.replace(':', '') + '00';
      const formattedEndTime = eventEnd.replace(':', '') + '00';
      const formattedStartDate = `${formattedDate}T${formattedStartTime}Z`;
      const formattedEndDate = `${formattedDate}T${formattedEndTime}Z`;
      const formattedDateYa = eventDate.split('T')[0];
      const formattedStartTimeYa = eventStart.padStart(5, '0') + ':00';
      const formattedEndTimeYa = eventEnd.padStart(5, '0') + ':00';
      const formattedStartDateYa = `${formattedDateYa}T${formattedStartTimeYa}Z`;
      const formattedEndDateYa = `${formattedDateYa}T${formattedEndTimeYa}Z`;
      const startDateObj = new Date(formattedStartDateYa);
      const endDateObj = new Date(formattedEndDateYa);
      const durationMinutes = (endDateObj.getTime() - startDateObj.getTime()) / 60000;
      const hours = Math.floor(durationMinutes / 60);
      const minutes = Math.round(durationMinutes % 60);
      const formattedDuration = `${hours.toString().padStart(2, '0')}${minutes.toString().padStart(2, '0')}`;
      const outlookStartDate = eventDate.split('T')[0]
      const [shours, sminutes] = eventStart.split(':');
      const [ehours, eminutes] = eventEnd.split(':');
      const outformattedStartTime = `${shours.padStart(2, '0')}:${sminutes.padStart(2, '0')}:00`;
      const outformattedEndTime = `${ehours.padStart(2, '0')}:${eminutes.padStart(2, '0')}:00`;
      const outformattedStartDate = `${outlookStartDate}T${outformattedStartTime}`;
      const outformattedEndDate = `${outlookStartDate}T${outformattedEndTime}`;
      const outlookSubject = encodeURIComponent(eventName);
      const outlookLocation = encodeURIComponent(eventLocation);
      const timezone = "GMT";
      const uid = this.generateUID()

      switch (calendarType) {
        case "google":
            window.open(
              `https://calendar.google.com/calendar/r/eventedit?text=${encodeURIComponent(
                eventName
              )}&dates=${encodeURIComponent(formattedStartDate)}%2F${encodeURIComponent(
                formattedEndDate
              )}&location=${encodeURIComponent(
                eventLocation
              )}&ctz=${encodeURIComponent(timezone)}`,
              "_blank"
            );
          break;
        case "outlook":
          window.open(
            `https://outlook.live.com/owa/?rru=addevent&&subject=${outlookSubject}&startdt=${outformattedStartDate}&enddt=${outformattedEndDate}&location=${outlookLocation}&allday=false&uid=${uid}&path=/calendar/view/Month`,
            "_blank"
          );
          break;
        case "yahoo":
          window.open(
            `https://calendar.yahoo.com/?v=60&view=d&type=20&title=${encodeURIComponent(eventName)}&st=${encodeURIComponent(formattedStartDate)}&dur=${formattedDuration}&desc=&in_loc=${encodeURIComponent(eventLocation)}&tz=GMT`,
            "_blank"
          );          
          break;
        case "download":
        case "apple":
        this.setState({
          eventDetails: {
            title: eventName,
            location: eventLocation.replaceAll(',', ''),
            startTime: moment(outformattedStartDate).local().format("YYYY-MM-DD HH:mm:ss"),
            endTime: moment(outformattedEndDate).local().format("YYYY-MM-DD HH:mm:ss"),
            durationError: '',
          }
        });
          break;
        default:
      }
    } catch (error) {

    }
};

  
  
  formatDate = () => {
    const currentDate = new Date();
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
    return currentDate.toLocaleDateString('en-US', options);
  }

   postCodeApi = async(addressCode :any)=>{
    try {
      const response = await axios.get(`https://api.postcodes.io/postcodes/${addressCode}/nearest`);
      const { data } = response;
      if (data && data.result) {
        this.setState({ address: data.result ,errorPostCode:false});
      } else {
        this.setState({ address: 'Address not found' });
      }
    } catch (error) {
      this.setState({ errorPostCode : true, address:[]});
    }
   }

   cancelSpeakTOExpert = ()=>{
    this.setState({openToExpertDialog :false})
   }

   cancelMeetWithManager = () => {
    this.onPopCancel()
    this.setState({meetWithExpertDialog: false})

   }

   meetLink =()=>{
    const meeting_link = this.state.customForm.attributes.meeting_link
    window.open(meeting_link, '_blank')
   }
     
  approveApiFN =()=>{    
    const header = {
     "Content-Type": configJSON.getUserListApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.approveKey = requestMessage.messageId;
  
    requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     `/approve_inquiry?inquiry_id=${this.state.enquiryDetails.isEnquiry ? this.state.enquiryDetails.id : this.props.navigation.getParam("id")}`
   );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  rejectApiFN =()=>{
    const header = {
     "Content-Type": configJSON.getUserListApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.rejectKey = requestMessage.messageId;
  
    requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     `/reject_inquiry?inquiry_id=${this.state.enquiryDetails.isEnquiry ? this.state.enquiryDetails.id : this.props.navigation.getParam("id")}`
   );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }
  
  rejectCancel=()=>{
    this.setState({rejectState:false});
  }
  
  checkRequiredFields() {
    return !Object.values(this.state.requiredInputFieldsValue).every(field => field !== "" && field !== null);
  }

  getValidationError() {
    let message = '';
    if (this.checkRequiredFields()) {
        message = 'Ensure all required information is provided.';
    } else if (this.state.locationCheck) {
        message = 'Location is mandatory.';
    } else if(this.state.previousEventDateLessThenCurrentDate){
      message = "Event Date can't be past date. Please select future date."
    }
    else if(this.state.previousEventDateEqualCurrentDate){
        message = "Event date can't be the same date. Please select another future date."
    } else if (this.state.eventDetails.durationError === "more than 9" || this.state.errorMessage === "Event duration can't be more than 9 hours") {
      message = "Event duration can't be more than 9 hours";
    } else if(this.state.eventDetails.durationError === "more than 5"){
      message = "Event duration can't be more than 5 hours"
    }
    return message;
  }

  requiredInputValidation(inputId:any){
    return this.state.continueToReview && (this.state?.requiredInputFieldsValue[inputId]==null || this.state?.requiredInputFieldsValue[inputId].length==0)
  }

  editEnquiry=()=>{
    this.props.navigation.navigate("CustomForm", {id:this.state.enquiryDetails.id});
  }

  getTimezone = () => {
    const timeZone = momentTimezone.tz.guess();
    const isDaylightSavingTime = momentTimezone().tz(timeZone).isDST();
    const uk_GreatBritainTimeZones = ['Europe/London', 'Europe/Guernsey', 'Europe/Isle_of_Man', 'Europe/Jersey'];
    const isUKorGreatBritain = uk_GreatBritainTimeZones.includes(timeZone);
    let format = isDaylightSavingTime ? "BST" : "GMT"
    if(!isUKorGreatBritain){
      format = "GMT";
    }
    return format
  }

  dupModalClose = () => {
    this.setState({dupModalOpen : false})
  }

  handleDownload = (enqID : string | number) => {
    if(enqID){
      this.setState({errorCell: true});
    }else{
      this.setState({errorCell: false});
    }
  }

  handleURL = (urlLink: string) =>{
    if(urlLink.includes("request-quote")){  
      const pathSegments = urlLink.split('/');
      const reqId = pathSegments[pathSegments.length - 1];  
      this.viewReq(reqId)      
    }
  }
  
  handleTimeValidatn = (value: string) => {
    value = value.replace(/\D/g, '');
    if (value.length > 4) {
      value = value.slice(0, 4);
    }

    if (value.length >= 2) {
      value = value.slice(0, 2) + ':' + value.slice(2);
    }
    return value;
  };

  renderProvCost = (num1 : string, num2  : string,) => {
    if(num1 && num2){
      let provCost = Number(num1.replace(",","")) + Number(num2.replace(",",""))
      return provCost.toLocaleString()
    }
  }

  handleFormatCorrectDate = (enteredDate: string | null | Date) => {
    if (typeof enteredDate == "string") {
      const [days, month, year] = enteredDate.split("/");
      const date = new Date(Number(year), Number(month) - 1, Number(days));
      return date
    } else {
      return enteredDate
    }
  }
  // Customizable Area End
}